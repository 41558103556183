import * as firebaseApp from "firebase/app";

function initializeDefaultApp(options) {
	firebaseApp.initializeApp(options);
}

function initializeAppByName(name, options) {
	firebaseApp.initializeApp(options, name);
}

function getApp(name) {
	if (!name || name === 'undefined') {
		return firebaseApp.getApp();
	}
	
	return firebaseApp.getApp(name);
}

export {
	initializeDefaultApp,
	initializeAppByName,
	getApp,
}
