import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import ContainedButton from "components/buttons/ContainedButton";
import { useTheme } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SecondaryTypographyCaption } from "components/typography/SecondaryTypography";
import VideoPlayer from "../../components/VideoPlayer";
import { PrimaryTypographyCaption } from "components/typography/PrimaryTypography";
import premiumIcon from "assets/images/lock.png";
import { hasValidSubscription } from "utils/subscription";
import sessionHelper from "utils/sessionHelper";
import { generateUniqueCode } from "utils/content";
import { throttle } from "lodash";
import { getProducts } from "features/payments/services/productsService";
import PremiumModal from "../../components/PremiumModal";
import SidebarSkeleton from "../../components/SidebarSkeleton";
import ContentMappingSkeleton from "../../components/ContentMappingSkeleton";
import { trackEvent, trackGtagEvent } from "utils/analytics";
import {
  getContentMappedToTopic,
  getSubjects,
  getTopics,
} from "features/insights/services/insightsService";

const ThemePageContainer = styled("div")`
  padding: 64px 0px 20px;
  height: 100%;
  position: relative;
`;

const ThemeIcon = styled("img")({
  display: "flex",
  height: 50,
});

const ContentThumbnail = styled("img")`
  height: ${(props) => (props.isMobile ? "100px" : "110px")};
  width: ${(props) => (props.isMobile ? "177px" : "194.7px")};
  background-color: ${(props) => props.backgroundColor};
  object-fit: ${(props) => (props.type === "category" ? "contain" : "fill")};
  :hover {
    cursor: pointer;
  }
`;

const PremiumIcon = styled("img")`
  position: absolute;
  top: 6%;
  right: 6%;
`;

const TopicTitle = styled("div")`
  position: absolute;
  width: 100%;
  text-align: center;
`;

const EmptyContainer = styled("div")`
  height: ${(props) => (props.height ? props.height : "0px")};
  width: 100%;
`;

export default function Subjects() {
  const elementsOffsets = useMemo(() => ({}), []); // Memoize the elementsOffsets object
  const themeSidebarOffsets = useMemo(() => ({}), []); // Memoize the themeSidebarOffsets object

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const contentScrollRef = useRef(null);

  const sidebarScrollRef = useRef(null);

  const [subjects, setSubjects] = useState([]);
  const [subjectTopicMapping, setSubjectTopicMapping] = useState({});
  const [topicContentMapping, setTopicContentMapping] = useState({});

  const [selectedThemeId, setSelectedThemeId] = useState();
  const [selectedTopicId, setSelectedTopicId] = useState();
  const [themeIdForTopic, setThemeIdForTopic] = useState();

  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [currentVideo, setCurrentVideo] = useState();
  const [currentVideoTheme, setCurrentVideoTheme] = useState();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const prevScrollPos = useRef(0);

  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [minPriceProduct, setMinimumPriceProduct] = useState(undefined);

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
  };

  const handleOpenPremiumDialog = () => {
    setOpenPremiumDialog(true);
  };

  const handleClosePremiumDialog = () => {
    setOpenPremiumDialog(false);
  };

  useEffect(() => {
    init();
    getProducts().then((_products) => {
      const minPriceProduct = _products.reduce(
        (min, product) => (product.price.list < min.price.list ? product : min),
        _products[0]
      );
      setMinimumPriceProduct(minPriceProduct);
    });
  }, []);

  const updateHash = () => {
    subjects
      .slice()
      .reverse()
      .some((section) => {
        const container = contentScrollRef?.current;
        if (selectedTopicId) {
          return false;
        }
        if (
          container.scrollTop + (!isMobile ? 100 : 150) >=
          elementsOffsets[section.id]
        ) {
          if (selectedThemeId === section.id) {
            return true;
          }
          sidebarScrollRef.current[isMobile ? "scrollLeft" : "scrollTop"] =
            themeSidebarOffsets[section.id] - (isMobile ? 0 : 100);
          setSelectedThemeId(section.id);

          return true;
        }
        return false;
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledUpdateHash = useCallback(
    throttle(() => updateHash(), 100),
    [updateHash]
  );

  const init = async () => {
    const subjects = await getSubjects();
    const mappings = {};

    const topics = await Promise.all(
      subjects.map((subject) => getTopics(subject.id, true))
    );

    for (let i = 0; i < subjects.length; i++) {
      mappings[subjects[i].id] = topics[i];
    }

    setSubjectTopicMapping(mappings);
    setSubjects(subjects);
    setSelectedThemeId(subjects[0].id);
    setIsSubscribed(hasValidSubscription(sessionHelper.subscriptionExpiry));
  };

  useEffect(() => {
    // calculate offsets for all sections
    subjects.forEach((s) => {
      elementsOffsets[s.id] = document.getElementById(s.id)?.offsetTop;
      themeSidebarOffsets[s.id] = document.getElementById(s.id + "_sidebar")?.[
        isMobile ? "offsetLeft" : "offsetTop"
      ];
    });
  }, [elementsOffsets, subjects, themeSidebarOffsets, isMobile]);

  const changeTheme = useCallback(
    async (themeId) => {
      setThemeIdForTopic(themeId);

      trackGtagEvent({
        type: "event",
        event: "select_item",
        payload: {
          item_list_id: themeId,
          item_list_name: subjects.find((subject) => subject.id === themeId)
            ?.name,
          items: [
            {
              item_id: themeId,
              item_name: subjects.find((subject) => subject.id === themeId)
                ?.name,
            },
          ],
        },
      });
    },
    [subjects]
  );

  const changeTopic = useCallback(
    async (themeId, topicId) => {
      if (topicContentMapping[topicId]) {
        setSelectedTopicId(topicId);
        setThemeIdForTopic(themeId);
        handleOpenCategoryDialog();
      } else {
        const contentMappedToTopic = await getContentMappedToTopic(
          themeId,
          topicId
        );

        setSelectedTopicId(topicId);
        setThemeIdForTopic(themeId);
        setTopicContentMapping({
          ...topicContentMapping,
          [topicId]: contentMappedToTopic,
        });
        handleOpenCategoryDialog();
        trackEvent("THEME_CATEGORY_SELECTED", {
          themeId: themeId,
          categoryId: topicId,
          title: subjectTopicMapping[themeId].find(
            (topic) => topic.id === topicId
          )?.title,
          sourceId: "subscription_site",
          sourceName: "subjectScreen",
        });

        trackGtagEvent({
          type: "event",
          event: "select_item",
          payload: {
            item_list_id: topicId,
            item_list_name: subjectTopicMapping[themeId].find(
              (topic) => topic.id === topicId
            )?.title,
            items: [
              {
                item_id: topicId,
                item_name: subjectTopicMapping[themeId].find(
                  (topic) => topic.id === topicId
                )?.title,
              },
            ],
          },
        });
      }
    },
    [topicContentMapping, subjectTopicMapping]
  );

  const handleScroll = useCallback(() => {
    const container = contentScrollRef?.current;
    if (selectedTopicId) {
      return;
    }
    if (container) {
      prevScrollPos.current = container.scrollTop;
      throttledUpdateHash();
    }
  }, [throttledUpdateHash, selectedTopicId]);

  useEffect(() => {
    const container = contentScrollRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const onVideoClicked = (video, themeId, categoryId) => {
    if (video.availability === "private" && !isSubscribed) {
      handleOpenPremiumDialog();
      return;
    }
    if (themeId && !categoryId) {
      const theme = subjects.find((theme) => theme.id === themeId);
      setCurrentVideoTheme(theme);
    }

    if (themeId && categoryId) {
      const categoryTheme = subjectTopicMapping[themeId].find(
        (category) => category.id === categoryId
      );
      setCurrentVideoTheme(categoryTheme);
    }

    setCurrentVideo(video);
    setShowVideoPlayer(true);
  };

  return (
    <ThemePageContainer>
      {!showVideoPlayer ? (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid
            item
            xs={12}
            md={3}
            sm={12}
            sx={{
              borderRight: `${!isMobile ? "2px solid grey" : "none"}`,
              mt: 4,
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              direction={isMobile ? "column" : "row"}
              ref={sidebarScrollRef}
              style={{
                overflow: "auto",
                height: !isMobile ? "100vh" : "90px",
              }}
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none" /* IE and Edge */,
                scrollbarWidth: "none",
              }}
            >
              {subjects.length ? (
                subjects.map((subject) => (
                  <Grid
                    item
                    xs={3}
                    md={12}
                    sm={3}
                    pl={1}
                    pr={1}
                    pt={1}
                    pb={1}
                    ml={1}
                    key={`${subject.id}_sidebar`}
                    id={`${subject.id}_sidebar`}
                  >
                    <ContainedButton
                      startIcon={
                        <ThemeIcon src={subject.thumbnail.image.url} />
                      }
                      fullWidth={true}
                      sx={{
                        backgroundColor: "transparent",
                        color:
                          subject.id === selectedThemeId ? "black" : "grey",
                        pl: 1,
                        pt: 0,
                        pb: 0,
                        mt: 0,
                        mb: 0,
                        justifyContent: "flex-start",
                        textAlign: "start",
                        height: "72px",
                        fontFamily: "Open Sans",
                        fontWeight: 900,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "transparent",
                          boxShadow:
                            subject.id === selectedThemeId ? 1 : "none",
                        },
                        boxShadow: subject.id === selectedThemeId ? 1 : "none",
                      }}
                      onClick={() => {
                        if (contentScrollRef.current)
                          contentScrollRef.current.scrollTop =
                            elementsOffsets[subject.id] -
                            (!isMobile ? 60 : 140);

                        changeTheme(subject.id);
                      }}
                    >
                      {subject.name}
                    </ContainedButton>
                  </Grid>
                ))
              ) : (
                <SidebarSkeleton />
              )}
            </Grid>
          </Grid>
          {Object.keys(subjectTopicMapping).length ? (
            <Grid
              item
              ref={contentScrollRef}
              xs={12}
              md={9}
              sm={12}
              sx={
                !isMobile
                  ? {
                      maxHeight: "100vh",
                      overflow: "auto",
                    }
                  : {
                      maxHeight: "70vh",
                      overflow: "auto",
                    }
              }
            >
              <Drawer
                anchor="right"
                open={openCategoryDialog}
                onClose={handleCloseCategoryDialog}
                variant="temporary"
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  "& .MuiDrawer-paper": {
                    width: isMobile ? "100%" : "72vw",
                    height: "100vh",
                    backgroundColor: "#FFFFFF",
                  },
                }}
              >
                {selectedTopicId ? (
                  <>
                    <Grid container sx={{ pt: 1 }}>
                      <Grid item sx={{ alignSelf: "start" }}>
                        <IconButton
                          size="large"
                          onClick={() => {
                            handleCloseCategoryDialog();
                            setSelectedTopicId(undefined);
                            setThemeIdForTopic(undefined);
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          alignSelf: "center",
                        }}
                      >
                        <SecondaryTypographyCaption
                          sx={{
                            fontWeight: 700,
                            fontSize: 28,
                            m: 0,
                            pl: 3,
                            color: "black",
                          }}
                        >
                          {
                            subjectTopicMapping[themeIdForTopic].find(
                              (topic) => {
                                return topic.id === selectedTopicId;
                              }
                            )?.title
                          }
                        </SecondaryTypographyCaption>
                      </Grid>
                    </Grid>
                    <Grid container>
                      {topicContentMapping[selectedTopicId].map((activity) => (
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={3}
                          sm={4}
                          key={activity.id}
                          sx={{
                            p: isMobile ? 1 : 2,
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <ContentThumbnail
                              src={activity.thumbnail.image.url}
                              alt={activity.title}
                              type="content"
                              backgroundColor="none"
                              isMobile={isMobile}
                              onClick={() => {
                                onVideoClicked(
                                  activity,
                                  themeIdForTopic,
                                  selectedTopicId
                                );
                              }}
                            />
                            {activity?.activityType === "content" &&
                            activity?.availability === "private" &&
                            !isSubscribed ? (
                              <PremiumIcon
                                src={premiumIcon}
                                alt="LOCKED"
                                height={32}
                                isMobile={isMobile}
                                onClick={() => {
                                  onVideoClicked(
                                    activity,
                                    themeIdForTopic,
                                    selectedTopicId
                                  );
                                }}
                              />
                            ) : null}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : null}
              </Drawer>
              {subjects.map((subject, index) => (
                <Grid container sx={{ mb: 2 }} key={subject.id} id={subject.id}>
                  <Grid item sm={12} md={12} xs={12}>
                    <PrimaryTypographyCaption
                      sx={{
                        fontSize: 20,
                        fontFamily: "Open Sans",
                        fontWeight: 900,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {subject.name}
                    </PrimaryTypographyCaption>
                  </Grid>
                  <Grid item sm={12} md={12} xs={12}>
                    <hr />
                  </Grid>

                  {subjectTopicMapping[subject.id].map((activity) => (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      sm={4}
                      lg={3}
                      key={`${activity.id}_${generateUniqueCode()}`}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        p: isMobile ? 1 : 2,
                      }}
                    >
                      <div
                        style={{ position: "relative", marginBottom: "36px" }}
                      >
                        <ContentThumbnail
                          src={activity.thumbnail.image.url}
                          alt={activity.title}
                          type={
                            activity?.type && activity?.type === "category"
                              ? activity?.type
                              : "content"
                          }
                          isMobile={isMobile}
                          backgroundColor={
                            activity?.type && activity?.type === "category"
                              ? activity.uiConfiguration.backgroundColor
                              : "none"
                          }
                          onClick={() => {
                            changeTopic(subject.id, activity.id);
                          }}
                        />
                        <TopicTitle>
                          <SecondaryTypographyCaption
                            sx={{
                              fontWeight: 900,
                              fontSize: ".9rem",
                              textAlign: "center",
                            }}
                          >
                            {activity.title}
                          </SecondaryTypographyCaption>
                        </TopicTitle>
                        {activity?.activityType === "content" &&
                        activity?.availability === "private" &&
                        !isSubscribed ? (
                          <PremiumIcon
                            src={premiumIcon}
                            alt="LOCKED"
                            height={32}
                            onClick={() => {
                              changeTheme(subject.id);
                            }}
                          />
                        ) : null}
                      </div>
                    </Grid>
                  ))}

                  {index === subjects.length - 1 ? (
                    <Grid item sm={12} md={12} xs={12}>
                      <EmptyContainer height={"70vh"} />
                    </Grid>
                  ) : null}
                </Grid>
              ))}
            </Grid>
          ) : (
            <ContentMappingSkeleton />
          )}
        </Grid>
      ) : (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{
            pl: 1,
            pr: 1,
            borderTop: !isMobile ? "1px solid grey" : "none",
          }}
        >
          <Grid item xs={12} sm={12} lg={1} md={1} p={0}>
            <IconButton
              size="large"
              onClick={() => {
                setShowVideoPlayer(false);
                setCurrentVideo(undefined);
                setCurrentVideoTheme(undefined);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} md={9} sx={{ p: !isMobile ? 4 : 0 }}>
            <VideoPlayer
              url={currentVideo.streams[0].url}
              showControls={true}
              light={true}
              theme={currentVideoTheme}
              activity={currentVideo}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            sx={{
              mt: 2,
              pl: !isMobile ? 2 : 0,
              height: "66vh",
              overflow: "auto",
              alignItems: "center",
              pt: 1,
              "::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none" /* IE and Edge */,
              scrollbarWidth: "none",
              borderLeft: !isMobile ? "1px solid grey" : "none",
            }}
          >
            <PrimaryTypographyCaption
              sx={{
                color: "black",
                display: "block",
                textAlign: "center",
                fontFamily: "Open Sans",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {selectedTopicId
                ? subjectTopicMapping[themeIdForTopic].find(
                    (category) => category.id === selectedTopicId
                  ).title
                : subjects.find((theme) => theme.id === selectedThemeId)?.title}
            </PrimaryTypographyCaption>
            <Grid container>
              {(selectedTopicId
                ? topicContentMapping[selectedTopicId]
                : subjectTopicMapping[selectedThemeId]
              ).map((activity) => (
                <Grid
                  item
                  xs={6}
                  md={12}
                  sm={4}
                  lg={12}
                  key={activity.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    p: isMobile ? 1 : 2,
                  }}
                  onClick={() => {
                    onVideoClicked(activity, selectedThemeId, selectedTopicId);
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <ContentThumbnail
                      src={activity.thumbnail.image.url}
                      alt={activity.title}
                      type="content"
                      backgroundColor="none"
                      isMobile={isMobile}
                    />
                    {activity?.activityType === "content" &&
                    activity?.availability === "private" &&
                    !isSubscribed ? (
                      <PremiumIcon
                        src={premiumIcon}
                        alt="LOCKED"
                        height={32}
                        onClick={() => {
                          onVideoClicked(
                            activity,
                            selectedThemeId,
                            selectedTopicId
                          );
                        }}
                      />
                    ) : null}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openPremiumDialog}
        onClose={handleClosePremiumDialog}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: "transparent",
            m: 0,
            p: 0,
          }}
        >
          {minPriceProduct ? (
            <PremiumModal
              showMaxPrice={false}
              durationUnit={minPriceProduct.duration.unit}
              durationValue={minPriceProduct.duration.value}
              minPrice={minPriceProduct.price.list}
              maxPrice={minPriceProduct.price.max}
              description={minPriceProduct.formattedDescription}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </ThemePageContainer>
  );
}
