import { styled } from "@mui/material/styles";
import logo from "assets/images/logo.png";
import React from "react";

const LogoContainer = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled("img")`
  margin: 0 auto;
  display: inline-block;
`;

function LoadingScreen() {
  return (
    <LogoContainer>
      <Logo src={logo} alt="Kutuki" />
    </LogoContainer>
  );
}

export default LoadingScreen;
