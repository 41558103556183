const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

function getContentAgeGroups(ageGroup) {
  if (!ageGroup) {
    return ["nursery", "lkg", "ukg"];
  }

  if (ageGroup.length === 0) {
    return ["nursery", "lkg", "ukg"];
  }

  if (ageGroup.length === 3) {
    return ["nursery", "lkg", "ukg"];
  }

  var contentAgeGroup = ageGroup;

  if (contentAgeGroup.length === 1) {
    contentAgeGroup.push(12);
  }

  const ageGroupMap = {
    nursery: [0, 3.99],
    lkg: [4, 4.99],
    ukg: [5, 5.99],
    grade_1: [6, 6.99],
    grade_2: [7, 7.99],
    grade1: [6, 6.99],
    grade2: [7, 7.99],
  };

  const suitableAgeGroups = [];

  Object.keys(ageGroupMap).forEach((grp) => {
    let ageGroupValues = ageGroupMap[grp];

    let isSuitable = false;

    isSuitable =
      clamp(
        contentAgeGroup[0],
        ageGroupValues[0] + 0.1,
        ageGroupValues[0] - 0.1
      ) === contentAgeGroup[0] ||
      clamp(contentAgeGroup[1], ageGroupValues[0] + 0.1, ageGroupValues[1]) ===
        contentAgeGroup[1] ||
      (contentAgeGroup[0] > ageGroupValues[0] &&
        contentAgeGroup[0] <= ageGroupValues[1] - 0.1) ||
      (contentAgeGroup[1] > ageGroupValues[0] &&
        contentAgeGroup[1] <= ageGroupValues[1]) ||
      (contentAgeGroup[0] <= ageGroupValues[0] &&
        contentAgeGroup[1] >= ageGroupValues[1]);

    if (isSuitable) {
      suitableAgeGroups.push(grp);
    }
  });

  return suitableAgeGroups;
}

function filterContentForLearningLevel(content, learningLevel) {
  return content.reduce((acc, activity) => {
    const ageGroup = activity?.age;
    if (!ageGroup) {
      acc.push(activity);
    } else {
      if (getContentAgeGroups(ageGroup).includes(learningLevel)) {
        acc.push(activity);
      }
    }
    return acc;
  }, []);
}

function generateUniqueCode() {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueCode = '';
    for (let i = 0; i < 6; i++) {
      uniqueCode += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return uniqueCode;
}
export { filterContentForLearningLevel, generateUniqueCode };
