import {styled} from "@mui/material/styles";
import {useNavigation} from "../Router";
import {SecondaryTypographyHeading1, SecondaryTypographyHeading3} from "components/typography/SecondaryTypography";
import TextButton from "components/buttons/TextButton";

const PageContainer = styled("div")`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Heading = styled(SecondaryTypographyHeading1)(({ theme }) => ({
	color: theme.palette.primary.main,
}));

const NotFoundPage = () => {
	const { navigateTo } = useNavigation();
	
	function onClickHomeButton() {
		navigateTo('/');
	}
	
	return (
		<PageContainer>
			<Heading>404</Heading>
			<SecondaryTypographyHeading3>Oops! This page doesn't exist :(</SecondaryTypographyHeading3>
			<TextButton
				fullWidth={false}
				onClick={onClickHomeButton}
			>
				Go Home
			</TextButton>
		</PageContainer>
	);
};

export default NotFoundPage;
