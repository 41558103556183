import moEngageAnalytics from "./moengage";
import firebaseAnalytics from './firebase/analytics';
import _omit from "lodash.omit";

async function trackEvent(name, payload) {
	firebaseAnalytics.init();
	await Promise.all([
		moEngageAnalytics.trackEvent(name, payload),
		firebaseAnalytics.trackEvent(name, payload),
	]);
}

async function trackGtagEvent({type="event",event, payload={}}) {
	if (typeof window !== 'undefined') {
		await window?.gtag(type, event, payload);
	}
}

async function setUserProperties(payload) {
	if (payload.email) {
		await moEngageAnalytics.trackEmail(payload.email);
	}
	
	if (payload.phone) {
		await moEngageAnalytics.trackPhoneNumber(payload.phone);
	}

	firebaseAnalytics.init();
	
	await moEngageAnalytics.trackUserProps(_omit(payload, ['email', 'phone', 'SubscriptionExpiryDateV2']));
	await firebaseAnalytics.trackUserProps(payload);
}

async function logoutMoe() {
	await moEngageAnalytics.logout();
}

export {
	trackEvent,
	setUserProperties,
	logoutMoe,
	trackGtagEvent
}
