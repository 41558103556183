import styled from "@emotion/styled";
import React from "react";

const ProgressContainer = styled("div")`
  display: flex;
  margin-bottom:20px;
`;

const ProgressItem = styled("div")`
  width: 100%;
  background-color: ${props => props.completed ? '#0085BA' : 'grey'};
  margin-right: 1px;
  height: 8px;
  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export default function SegmentedProgress(props) {
  const numberOfSteps = Array.from(
    { length: props.steps },
    (_, index) => index
  );

  return (
    <ProgressContainer>
      {numberOfSteps.map((i) => (
        <ProgressItem key={i} completed={props.completed >= i + 1} />
      ))}
    </ProgressContainer>
  );
}
