import React from "react";
import { useNavigate } from "react-router";
import { restoreSession, signOut } from "features/authenticate/services/authService";
import sessionHelper from "utils/sessionHelper";
import LoadingOverlay from "./LoadingOverlay";

const subMinutes = function (dt, minutes) {
    return new Date(dt.getTime() - minutes * 60000);
  };
  
export function SessionSync({ children }) {
  
    const navigate = useNavigate();
  
    const [loading, setLoading] = React.useState(true);
  
    const isTokenExpiring = () =>
      subMinutes(new Date(sessionHelper.tokenExpiry), 5) <= new Date();
  
    const isRefreshTokenExpiring = () =>
      subMinutes(new Date(sessionHelper.refreshTokenExpiry), 5) <= new Date();
  
    const goToSignIn = async () => {
      await signOut();
      navigate('/auth/login');
    };
  
    async function onLoad() {
  
      try {
        // if the refresh token is expiring then sign out and redirect to sign in
        const kutRefreshTokenExpiry = localStorage.getItem(
          "kutRefreshTokenExpiry"
        );
  
        if (kutRefreshTokenExpiry) {
          console.log("kutRefreshTokenExpiry", kutRefreshTokenExpiry);
          sessionHelper.refreshTokenExpiry =
            new Date(kutRefreshTokenExpiry).getTime() || new Date().getTime();
        }
  
        if (isRefreshTokenExpiring()) {
          console.log("refresh token expiring", sessionHelper.refreshTokenExpiry);
          await goToSignIn();
        }
  
        if (sessionHelper.isLoggedIn()) {
          if (isTokenExpiring())
            sessionHelper.token = null;
          
          await restoreSession();
        }
  
        console.log(`onLoad -> session restore check complete`);
      } catch (e) {
        console.error(`onLoad -> error attempting to restore session`, e);
        await goToSignIn();
      }
    }
  
    async function setupRefresh() {
      try {
        if (isRefreshTokenExpiring()) {
          await goToSignIn();
        }
  
        if (sessionHelper.isLoggedIn()) {
          if (isTokenExpiring())
            sessionHelper.token = null;
  
          await restoreSession();
        }
      } catch (e) {
        console.error("setupTokenRefresh -> error refreshing token", e.messgae);
        await goToSignIn();
      }
    }
  
    React.useEffect(() => {
      onLoad()
        .then(() => setLoading(false))
        .catch((e) => setLoading(false));
  
      const timerId = setInterval(setupRefresh, 3600000);
  
      return () => clearInterval(timerId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return loading ? < LoadingOverlay/> : <>{children}</>;
  }
