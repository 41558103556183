import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import englishIcon from "assets/images/english_icon.png";
import gkIcon from "assets/images/gk_icon.png";
import mathIcon from "assets/images/maths_icon.png";
import otherIcon from "assets/images/other_icon.png";
import {
  PrimaryTypographyCaption,
  PrimaryTypographyHeading6,
} from "components/typography/PrimaryTypography";
import SegmentedProgress from "./SegmentedProgress";
import VerticalLinearStepper from "./VerticalLinearStepper";
import TopicSkeleton from "./TopicSkeleton";
import PropTypes from "prop-types";

const subjectIcons = {
  gk: gkIcon,
  other: otherIcon,
  math: mathIcon,
  eng: englishIcon,
  hindi: otherIcon,
};

const SubjectIcon = styled("img")`
  display: inline-block;
  width: 30px;
  margin-left: 5px;
  margin-right: 10px;
  color: #fff;
`;

export default function SubjectAccordion({
  accordionStates,
  getTopicsMappedToSubject,
  setAccordionStates,
  subject,
  computedStats,
}) {
  return (
    <Accordion
      expanded={accordionStates[subject.code]}
      sx={{
        borderRadius: 16,
        backgroundColor: "transparent",
        boxShadow: 0,
      }}
      onChange={(event, expanded) => {
        if (expanded) {
          getTopicsMappedToSubject(subject.id, subject.code);
        }
        setAccordionStates({ ...accordionStates, [subject.code]: expanded });
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#F24954" }} />}
        sx={{
          backgroundColor: "#9FD6FF",
          borderRadius: 16,
          minHeight: "56px",
          maxHeight: "56px",
          alignItems: "center",
          "&.Mui-expanded": {
            minHeight: "56px",
            maxHeight: "56px",
          },
        }}
      >
        <SubjectIcon
          src={subjectIcons[subject.code] || otherIcon}
          alt={"english"}
        />
        <PrimaryTypographyCaption sx={{ fontSize: 16, fontWeight: 700 }}>
          {subject.name}
        </PrimaryTypographyCaption>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "transparent",
        }}
      >
        {computedStats.subjectStats[subject.code].topics?.length ? (
          computedStats.subjectStats[subject.code].topics.map((topic) => (
            <Accordion
              key={topic.id}
              sx={{
                backgroundColor: "white",
                // boxShadow: 0,
                borderColor: "transparent",
                borderRadius: "16px !important",
                mb: 2,
                "&:before": {
                  backgroundColor: "transparent !important",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  borderRadius: "16px",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignSelf={"center"}
                >
                  <Grid item>
                    <PrimaryTypographyHeading6
                      sx={{ fontWeight: 600, fontSize: 16 }}
                    >
                      {topic.title}
                    </PrimaryTypographyHeading6>
                  </Grid>
                  <Grid item>
                    <PrimaryTypographyHeading6
                      sx={{ fontWeight: 600, fontSize: 16, mr: 1 }}
                    >
                      {`${topic.activitiesCompletedCount}/${topic.activities.length} Activities`}
                    </PrimaryTypographyHeading6>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "transparent",
                  borderRadius: "16px",
                }}
              >
                <SegmentedProgress
                  steps={topic.activities.length}
                  completed={topic.activitiesCompletedCount}
                />
                <VerticalLinearStepper data={topic.activities} />
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <TopicSkeleton />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

SubjectAccordion.propTypes = {
  accordionStates: PropTypes.object,
  getTopicsMappedToSubject: PropTypes.func,
  setAccordionStates: PropTypes.func,
  subject: PropTypes.object,
  computedStats: PropTypes.object,
};
