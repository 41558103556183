import React from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import styled from "@emotion/styled";

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 14px;
  max-width: 540px;
  margin-inline: auto;
`;

export default function PinPut({onOtpChange, error}) {
  const [otp, setOtp] = React.useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
    onOtpChange(newValue);
  };

  return (
    <MuiOtpInputStyled
      length={6}
      hidden={true}
      value={otp}
      onChange={handleChange}
      TextFieldsProps={{ type: "number", min: 0, max: 9, placeholder: "-", error: error}}
    />
  );
}
