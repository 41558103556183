import NotFoundPage from "./features/404";
import Login from "./features/authenticate/Login";
import Register from "features/authenticate/Register";
import ProductListing from "features/payments/ProductListing";
import { SessionSync } from "components/SessionSync";
import sessionHelper from "./utils/sessionHelper";
import PageShell from "./components/PageShell";
import * as ReactRouterDOM from "react-router-dom";
import { queryParamStore } from "./utils/urlUtils";
import Insights from "features/insights/Insights";
import Themes from "features/themes/Themes";
import Subjects from "features/subjects/Subjects";

function useNavigation() {
  const navigate = ReactRouterDOM.useNavigate();
  
  function navigateTo(to, extra) {
    navigate(queryParamStore.queryParams(to), extra);
  }
  
  return { navigateTo };
}

function Navigate(props) {
  console.log(`Navigate -> UTM Params are`, queryParamStore.queryParams(props.to));
  return (
    <ReactRouterDOM.Navigate to={queryParamStore.queryParams(props.to)} />
  );
}

function AppLayout() {
  const isLoggedIn = sessionHelper.isLoggedIn();
  
  return (
    isLoggedIn
      ? <PageShell><ReactRouterDOM.Outlet /></PageShell>
      : <Navigate to="/auth/login" />
  );
}

function AuthenticationLayout() {
  const isLoggedIn = sessionHelper.isLoggedIn();
  
  return (
    !isLoggedIn
      ? <ReactRouterDOM.Outlet />
      : <Navigate to="/" />
  );
}

const RouterComponent = () => {
  return (
    <ReactRouterDOM.BrowserRouter basename={process.env.REACT_APP_STAGE === "production" ? "/app" : undefined}>
    <SessionSync>
        <ReactRouterDOM.Routes>
          <ReactRouterDOM.Route path="/" element={<AppLayout />}>
            <ReactRouterDOM.Route index element={<ProductListing/>} />
          </ReactRouterDOM.Route>
          <ReactRouterDOM.Route path="/insight" element={<AppLayout />}>
            <ReactRouterDOM.Route index element={<Insights/>} />
          </ReactRouterDOM.Route>
          <ReactRouterDOM.Route path="/videos" element={<AppLayout />}>
            <ReactRouterDOM.Route index element={<Themes/>} />
          </ReactRouterDOM.Route>
          <ReactRouterDOM.Route path="/subjects" element={<AppLayout />}>
            <ReactRouterDOM.Route index element={<Subjects/>} />
          </ReactRouterDOM.Route>
          <ReactRouterDOM.Route path="auth" element={<AuthenticationLayout />}>
            <ReactRouterDOM.Route index path="login" element={<Login />} />
            <ReactRouterDOM.Route path="register" element={<Register />} />
          </ReactRouterDOM.Route>
          <ReactRouterDOM.Route path="*" element={<NotFoundPage />} />
        </ReactRouterDOM.Routes>
      </SessionSync>
    </ReactRouterDOM.BrowserRouter>
  );
};

export default RouterComponent;
export {
  RouterComponent,
  useNavigation,
}
