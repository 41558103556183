import { Grid, Skeleton } from "@mui/material";
import React from "react";

export default function ContentMappingSkeleton({ isMobile }) {
  return (
    <Grid
      item
      xs={12}
      md={9}
      sm={12}
      sx={{ maxHeight: "100vh", overflow: "auto" }}
    >
      <Grid container>
        <Grid item sm={12} md={12} xs={12}>
          <Skeleton variant="rectangular" width="100%" height={10} />
        </Grid>
        {Array.from({ length: 16 }, (_, index) => index).map((index) => (
          <Grid
            item
            xs={6}
            md={4}
            sm={4}
            lg={3}
            key={`${index}_content_thumbnail_skeleton`}
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              p: isMobile ? 1 : 2,
            }}
          >
            <Skeleton
              variant="rectangular"
              width={isMobile ? 159.3 : 194.7}
              height={isMobile ? 90 : 110}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
