import config from "configuration";
import googlePlayBadge from "assets/images/google-play-badge.png";
import appStoreBadge from "assets/images/app-store-badge.png";
import {styled} from "@mui/material/styles";

const StoreBadgeImage = styled("img")`
  margin: 0 5px;
  display: inline-block;
`;

function StoreBadge(props) {
	return (
		<a
			href={props.url}
			target="_blank"
			rel="noreferrer"
		>
			<StoreBadgeImage src={props.image} alt={props.altText} />
		</a>
	);
}

function GooglePlayBadge() {
	return (
		<StoreBadge
			url={config.play_store_url}
			image={googlePlayBadge}
			altText="Get it on Google Play"
		/>
	);
}

function AppStoreBadge() {
	return (
		<StoreBadge
			url={config.app_store_url}
			image={appStoreBadge}
			altText="Download on the App Store"
		/>
	);
}

export {
	GooglePlayBadge,
	AppStoreBadge,
}
