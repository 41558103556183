import { APIClient } from "utils/httpClient";
import sessionHelper from "../../../utils/sessionHelper";
import rollbarService from "../../../utils/rollbarService";
import { getDocument } from "utils/firebase/firestore";
import { setUserProperties, trackEvent, trackGtagEvent } from "utils/analytics";
import moEngageAnalytics from "utils/moengage";
import { googleSignOut } from "utils/firebase/auth";
import {queryParamStore} from "../../../utils/urlUtils";

async function signIn({
  phone,
  firebaseUid,
  firebaseToken,
  otp,
  isStaticOTP,
  mode,
  email,
}) {
  try {
    const result = await new APIClient({
      path: `/signin`,
      payload: {
        phone: phone ? phone : undefined,
        mode: mode ? mode : "phone",
        vendor: isStaticOTP ? "msg91" : "firebase",
        firebaseUid,
        firebaseToken,
        otp,
        email,
        authenticationCode: queryParamStore.getParam('ac'),
      },
    }).post();

    if (result.code !== 200) {
      throw new Error(result.message);
    }

    if (!result?.isRegistered) {
      return {
        isRegistered: false,
      };
    }

    const { user, subscription, tokens, child } = result.data;
    // store the data in the session data module.
    sessionHelper.setUserInformation({ profile: {...user, childId: child[0].id, learningLevel: child[0].learningLevel, starsEarned: child[0].starsEarned } });
    sessionHelper.setTokens(tokens);
    sessionHelper.setSubscription({
      subscription: {
        ...subscription,
        durationUnit: subscription?.product?.durationUnit,
        durationValue: subscription?.product?.durationValue,
      },
    });

    localStorage.setItem("kutProfile", JSON.stringify({ userId: user.id }));

    trackEvent("SIGN_IN_COMPLETED", {
      email: sessionHelper.email,
      phone: sessionHelper.phone,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
      authenticationMode: mode ? mode : "phone",
      sourceId: "subscription_site",
      sourceName: "signInScreen",
    });

    trackGtagEvent({type:"event", event: "login", payload: {
      method: mode ? mode : "phone"
    }});

    setUserProperties({
      email: sessionHelper.email,
      phone: sessionHelper.phone,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
    });

    moEngageAnalytics?.trackUserID(sessionHelper.id);

    if(sessionHelper.phone) {
      moEngageAnalytics?.trackPhoneNumber(sessionHelper.phone);
    }

    rollbarService?.configure({
      payload: {
        person: {
          id: sessionHelper.id,
          email: sessionHelper.email,
          phone: sessionHelper.phone,
        }
      },
    });

    return {
      isRegistered: true,
    };
  } catch (error) {
    rollbarService?.logError("signIn -> error while signin", {
      code: error.code || "",
      message: error.message,
    });

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: true,
      description: error.message,
    }});

    throw new Error(error.message);
  }
}

async function signUp({ phone, parentName, childName, emailId, age, gender, authenticationCode, }) {
  try {
    const result = await new APIClient({
      path: `/signUp`,
      payload: {
        phone: phone,
        mode: "phone",
        type: "parent",
        name: parentName || "",
        email: emailId || undefined,
        child: {
          name: childName,
          gender: gender,
          age: age,
        },
        authenticationCode: queryParamStore.getParam('ac')
      },
    }).post();
    if (result.code !== 200) {
      throw new Error(result.message);
    }

    const { user, subscription, tokens, child } = result.data;
    // store the data in the session data module.
    sessionHelper.setUserInformation({ profile: {...user, childId: child[0].id, learningLevel: child[0].learningLevel, starsEarned: child[0].starsEarned} });
    sessionHelper.setTokens(tokens);
    sessionHelper.setSubscription({
      subscription: {
        ...subscription,
        durationUnit: subscription?.product?.durationUnit,
        durationValue: subscription?.product?.durationValue,
      },
    });

    localStorage.setItem("kutProfile", JSON.stringify({ userId: user.id }));

    trackEvent("SignedUp", {
      email: sessionHelper.email,
      phone: sessionHelper.phone,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
      sourceId: "subscription_site",
      sourceName: "signInScreen",
    });

    trackGtagEvent({type:"event", event: "sign_up", payload:{
      method: "phone"
    }});

    setUserProperties({
      email: sessionHelper.email,
      phone: sessionHelper.phone,
      userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
    });

    moEngageAnalytics.trackUserID(sessionHelper.id, true)

    if(sessionHelper.email) {
      moEngageAnalytics?.trackEmail(sessionHelper.email);
    }

    rollbarService?.configure({
      payload: {
        person: {
          id: sessionHelper.id,
          email: sessionHelper.email,
          phone: sessionHelper.phone,
        }
      },
    });
    return {
      isRegistered: true,
    };
  } catch (error) {
    rollbarService?.logError("signUp -> error while signUp", {
      code: error.code || "",
      message: error.message,
    });

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: true,
      description: error.message,
    }});

    return {
      isRegistered: false,
    };
  }
}

async function restoreSession() {
  let kutProfile = localStorage.getItem("kutProfile");

  if (!kutProfile) {
    throw new Error(
      "Local profile not found, exiting the restore session flow"
    );
  }

  const result = await new APIClient({
    path: "/auth/access",
    headers: {
      "kutuki-refresh-token": localStorage.getItem("kutRefreshToken"),
    },
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  sessionHelper.token = result.data.accessToken;
  sessionHelper.tokenExpiry = result.data.accessExpireAt;

  const userData = await getUserData(JSON.parse(kutProfile).userId);

  const { user, subscription, children } = userData;

  sessionHelper.setUserInformation({ profile: {...user, childId: children[0].id,learningLevel: children[0].learningLevel, starsEarned: children[0].starsEarned} });

  sessionHelper.setSubscription({
    subscription: {
      ...subscription,
      durationUnit: subscription?.product?.durationUnit,
      durationValue: subscription?.product?.durationValue,
    },
  });

  rollbarService?.configure({
    payload: {
      person: {
        id: sessionHelper.id,
        email: sessionHelper.email,
        phone: sessionHelper.phone,
      }
    },
  });
}

async function getUserData(userId) {
  try {
    const result = await new APIClient({
      path: `/user/${userId}`,
    }).get();

    if (result.code !== 200) {
      throw new Error(result.message);
    }

    return {
      subscription: result.data.subscription,
      user: {
        id: result.data.id,
        name: result.data.name,
        phone: result.data.phone,
        email: result.data.emailId,
      },
      children: result.data.children
    };
  } catch (error) {
    rollbarService?.logError(
      "getUserData -> error while getting userData",
      {
        code: error.code || "",
        message: error.message
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: true,
      description: error.message,
    }})
  }
}

async function getSignInPageConfig() {
  try {
    const snapshot = await getDocument({
      appName: "kutuki-secondary",
      collectionName: "appConfiguration",
      id: "signInConfig",
    });

    const signInConfig = snapshot.data();
    return signInConfig;
  } catch (error) {
    console.log(error);
  }
}

async function signOut() {
  sessionHelper.onSignOut();
  await googleSignOut();
  trackEvent("signOutClicked", {
    "sourceId": "subscription_site",
  });
  await moEngageAnalytics.logout();
}

export { signIn, signUp, restoreSession, signOut, getSignInPageConfig };
