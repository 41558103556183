import {useEffect} from "react";
import {styled} from "@mui/material/styles";
import Dialog, {DialogTitle, DialogContent} from "components/Dialog";
import PropTypes from "prop-types";
import TextButton from "components/buttons/TextButton";
import checkIcon from "assets/svg/check-icon.svg";
import {PrimaryTypographyText} from "components/typography/PrimaryTypography";
import Confetti from "react-confetti";

const TitleContainer = styled(DialogTitle)`
	background-color: #006086;
	color: #fff;
	font-weight: bold;
	font-size: 18px;
	padding: 5px 10px;
`;

const CouponAppliedIconContainer = styled("div")`
	height: 40px;
	width: 40px;
	margin: 20px auto;
`;

const CouponAppliedIcon = styled("img")`
	height: 40px;
	width: 40px;
	text-align: center;
`;

function ConfettiBackground() {
	return (
		<Confetti
			height={document.body.scrollHeight}
			width={window.innerWidth}
			tweenDuration={1000}
			run={true}
		/>
	);
}

function CouponAppliedDialog({ title, open, onClose }) {
	// timer to auto close the coupon applied dialog.
	useEffect(() => {
		if (open) {
			const id = setTimeout(() => {
				onClose();
				clearTimeout(id);
			}, 5000)
		}
	}, [open, onClose])
	
	return (
		<>
			{open ? <ConfettiBackground /> : null }
			<Dialog open={open} maxWidth="xs" hideBackdrop fullWidth>
				<TitleContainer>
					Coupon Applied!
				</TitleContainer>
				<DialogContent>
					<CouponAppliedIconContainer>
						<CouponAppliedIcon src={checkIcon} alt="Tick" />
					</CouponAppliedIconContainer>
					<PrimaryTypographyText align="center">
						{`Your coupon ${title} was applied successfully!`}
					</PrimaryTypographyText>
					<TextButton onClick={onClose}>
						Yay!
					</TextButton>
				</DialogContent>
			</Dialog>
		</>
	);
}

CouponAppliedDialog.propTypes = {
	amount: PropTypes.number.isRequired,
	open: PropTypes.bool.isRequired,
	title: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};

CouponAppliedDialog.defaultProps = {
	amount: 0,
	open: false,
	title: "Coupon Applied",
};

export default CouponAppliedDialog;
