const configuration = {
	moengage_app_id: process.env.REACT_APP_MOENGAGE_APP_ID,
	
	rpKey: process.env.REACT_APP_RAZORPAY_KEY,
	
	app_version: process.env.REACT_APP_VERSION,
	
	freshchat_token: process.env.REACT_APP_FRESHCHAT_TOKEN,
	freshchat_host: process.env.REACT_APP_FRESHCHAT_HOST,
	freshchat_widget_uuid: process.env.REACT_APP_FRESHCHAT_WIDGET_UUID,
	
	app_store_url: "https://apps.apple.com/in/app/kutuki-kids-learning-app/id1447090784?utm_source=account&utm_medium=account&utm_campaign=account",
	play_store_url: "https://play.google.com/store/apps/details?id=com.soniqmantra.kutuki&hl=en_IN&gl=US&utm_source=account&utm_medium=account&utm_campaign=account",
	
	kutuki_facebook: "https://www.facebook.com/kutukikids/",
	kutuki_instagram: "https://www.instagram.com/kutukikidsofficial/?hl=en",
	kutuki_youtube: "https://www.youtube.com/channel/UCK7MW5jNwjTqwGWsLBUCy7w",
	kutuki_twitter: "https://twitter.com/kutukikids?lang=en",
	
	default_email: process.env.REACT_APP_RAZORPAY_DEFAULT_EMAIL,
};

export default configuration;
