import Rollbar from "rollbar";

class RollbarService {
  #instance;
  
  constructor() {
    this.#instance = null
  }
  
  init() {
    this.#instance = new Rollbar({
      enabled: !(process.env.NODE_ENV === "development"),
      accessToken: '833a031776904815a02cc3a9a4abecf8',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process.env.NODE_ENV === "development" ? "local" : process.env.REACT_APP_STAGE,
      }
    });
  }
  
  async logEvent(message) {
    await this.#instance.log(message);
  }

  async configure(data) {
    await this.#instance.configure(data);
  }

  async logError(message, payload) {
    await this.#instance.error(message, payload);
  }
  
  async setPerson(id) {
    await this.#instance.configure({
      payload: {
        person: { id }
      },
    });
  }
}

const rollbarService = new RollbarService();

export default rollbarService;
