import { useState } from "react";
import { Box, Drawer, IconButton, List, ListItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "components/Header";
import logo from "assets/images/logo.png";
import AssessmentIcon from "@mui/icons-material/Assessment";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SchoolIcon from '@mui/icons-material/School';
import TextButton from "./buttons/TextButton";
import { useNavigation } from "../Router";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Main = styled("main")`
  height: 100%;
  width: 100%;
`;

const Logo = styled("img")({
  display: "block",
  marginLeft: 40,
  marginRight: 40,
  marginTop: 30,
  marginBottom: 20,
});

function PageShell({ children }) {
  const { navigateTo } = useNavigation();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setDrawerOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setDrawerOpen(!drawerOpen);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Header handleDrawerToggle={handleDrawerToggle} isMobile={isMobile} />
      <Drawer
        open={drawerOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: (location.pathname === "/videos" || location.pathname === "/subjects") && !isMobile ? 96 : 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: (location.pathname === "/videos" || location.pathname === "/subjects") && !isMobile ? 96 : 240,
            border: "none",
            backgroundColor: "#006086",
          },
        }}
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
      >
        {(location.pathname === "/videos" || location.pathname === "/subjects") && !isMobile ? null : <Logo src={logo} alt={"Kutuki"} style={{ height: 64 }} /> }
        <List>
          {[
            { text: "Premium", icon: WorkspacePremiumIcon, path: "/" },
            { text: "Learning Report", icon: AssessmentIcon, path: "/insight" },
            { text: "Videos", icon: OndemandVideoIcon, path: "/videos" },
            { text: "Subjects", icon: SchoolIcon, path: "/subjects" },
          ].map((val) => (
            <ListItem key={val.text}>
              {(location.pathname === "/videos" || location.pathname === "/subjects") && !isMobile ? (
                <IconButton
                  variant="contained"
                  sx={{
                    m: 0,
                    pl: 2,
                    pr:2,
                    color: "white",
                    backgroundColor:
                      val.path === location.pathname ? "#0080A0" : "#006086",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#0080A0",
                    },
                    borderRadius: 2,
                    justifyContent:"center"
                  }}
                  onClick={() => {
                    navigateTo(val.path);
                    if (isMobile) {
                      handleDrawerToggle();
                    }
                  }}
                >
                  <val.icon color="red" />
                </IconButton>
              ) : (
                <TextButton
                  sx={{
                    color: "white",
                    textTransform: "none",
                    pl: 2,
                    pr: 2,
                    ml: 1,
                    mr: 1,
                    borderRadius: 2,
                    fontFamily: "Fredoka",
                    fontSize: 16,
                    fontWeight: 600,
                    justifyContent: "start",
                    backgroundColor:
                      val.path === location.pathname ? "#0080A0" : "#006086",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#0080A0",
                    },
                  }}
                  onClick={() => {
                    navigateTo(val.path);
                    if (isMobile) {
                      handleDrawerToggle();
                    }
                  }}
                  startIcon={<val.icon />}
                >
                  {val.text}
                </TextButton>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main>{children}</Main>
    </Box>
  );
}

PageShell.propTypes = {};

PageShell.defaultProps = {};

export default PageShell;
