import { Grid, Skeleton } from "@mui/material";
import React from "react";

export default function SidebarSkeleton() {
  return (
    <>
      {Array.from({ length: 10 }, (_, index) => index).map((index) => (
        <Grid
          item
          xs={3}
          md={12}
          sm={3}
          pl={1}
          pr={1}
          pt={1}
          pb={1}
          ml={1}
          key={`${index}_sidebar_skeleton`}
          id={`${index}_sidebar_skeleton`}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Skeleton variant="circular" height={30} width={30} />
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Skeleton variant="rectangular" height={20} width={200} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
