import MuiAccordionSummary from '@mui/material/AccordionSummary';
import PropTypes from 'prop-types';

function AccordionSummary({ children, ...props }) {
	return (
		<MuiAccordionSummary {...props}>
			{children}
		</MuiAccordionSummary>
	);
}

AccordionSummary.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	expandIcon: PropTypes.node,
	sx: PropTypes.any,
};

AccordionSummary.defaultProps = {};

export default AccordionSummary;
