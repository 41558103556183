import MuiButton from '@mui/material/Button';
import PropTypes from 'prop-types';

function ContainedButton(props) {
  return (
    <MuiButton
      color={props.color}
      classes={props.classes}
      className={props.className}
      disabled={props.disabled}
      endIcon={props.endIcon}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      size={props.size}
      startIcon={props.startIcon}
      variant='contained'
      sx={props.sx}
    >
      {props.children}
    </MuiButton>
  );
}

ContainedButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([ 'success', 'error', 'info', 'primary', 'secondary' ]),
  ]),
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
  startIcon: PropTypes.node,
  sx: PropTypes.any,
};

ContainedButton.defaultProps = {
  disabled: false,
  fullWidth: true,
  size: 'medium'
};

export default ContainedButton;
