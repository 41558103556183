import {getDocuments, snapshotToArray} from "utils/firebase/firestore";

async function getProducts() {
	try {
		const snapshot = await getDocuments({
			appName: 'kutuki-secondary',
			collectionName: 'products',
			conditions: [
				{ field: 'schemaVersion', operator: 'eq', value: 5 },
				{ field: 'platform', operator: 'arrayContains', value: 'kutuki' },
			],
		});
		
		const result = snapshotToArray(snapshot);
		
		return result.sort((a, b) => {
			if (a.displayPosition < b.displayPosition) {
				return -1;
			} else if (a.displayPosition > b.displayPosition) {
				return 1;
			} else {
				return 0;
			}
		});
	} catch (e) {
		console.error(`getProducts -> error ${e.message} ${e.stack}`);
		// TODO: rollbar log event
		throw new Error(e.message);
	}
}

export {
	getProducts,
}
