import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import {initializeAppByName, initializeDefaultApp} from 'utils/firebase/client';
import rollbarService from 'utils/rollbarService';
import configuration from 'configuration';

const initFirebase = () => {
	const firebaseConfig = {
	  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
	  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
	  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
	  appId: process.env.REACT_APP_FIREBASE_APP_ID,
	  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	};

	initializeDefaultApp(firebaseConfig);
	
	initializeAppByName(
		"kutuki-secondary",
		{
			apiKey: process.env.REACT_APP_FIREBASE_SECONDARY_API_KEY,
			authDomain: process.env.REACT_APP_FIREBASE_SECONDARY_AUTH_DOMAIN,
			databaseURL: process.env.REACT_APP_FIREBASE_SECONDARY_DB_URL,
			projectId: process.env.REACT_APP_FIREBASE_SECONDARY_PROJECT_ID,
			storageBucket: process.env.REACT_APP_FIREBASE_SECONDARY_BUCKET,
			messagingSenderId: process.env.REACT_APP_FIREBASE_SECONDARY_MESSAGING_SENDER,
			appId: process.env.REACT_APP_FIREBASE_SECONDARY_APP_ID,
			measurementId: process.env.REACT_APP_FIREBASE_SECONDARY_MEASUREMENT_ID,
		}
	)
};

initFirebase();
rollbarService.init();
rollbarService?.configure({
  appVersion: configuration.app_version,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
