import React from "react";
import CircularStaticProgress from "./CircularStaticProgress";
import { SecondaryTypographyText } from "./typography/SecondaryTypography";
import { Backdrop, Box } from "@mui/material";

const LoadingOverlayContext = React.createContext({
  _loading: false,
  _message: "",
  _setMessage: () => {},
});

const LoadingOverlay = () => {
  const { _loading, _message } = React.useContext(LoadingOverlayContext);

  return (
    <Backdrop
      open={_loading}
      sx={{
        zIndex: 100,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          minWidth: "200px",
          alignItems: "center",
        }}
      >
        <SecondaryTypographyText variant="h6" sx={{ marginBottom: 16 }}>
          {_message}
        </SecondaryTypographyText>
        <CircularStaticProgress color="secondary" />
      </Box>
    </Backdrop>
  );
};

const LoadingOverlayProvider = ({ children }) => {
  const [_loading, _setLoading] = React.useState(false);
  const [_message, _setMessage] = React.useState("");

  return (
    <LoadingOverlayContext.Provider
      value={{ _loading, _setLoading, _message, _setMessage }}
    >
      <LoadingOverlay />
      {children}
    </LoadingOverlayContext.Provider>
  );
};

const useLoadingOverlay = () => {
  const { _setLoading, _setMessage } = React.useContext(LoadingOverlayContext);

  const showLoadingOverlay = (message = "") => {
    _setMessage(message);
    _setLoading(true);
  };

  const hideLoadingOverlay = () => {
    _setMessage("");
    _setLoading(false);
  };

  return { showLoadingOverlay, hideLoadingOverlay };
};

export default LoadingOverlay;
export { LoadingOverlayProvider, useLoadingOverlay };
