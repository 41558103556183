import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import PropTypes from "prop-types";

const donutColors = {
  gk: "#FCBF49",
  other: "#3aa8c1",
  math: "#8FC93A",
  eng: "#B388EB",
  hindi: "#FFA500",
  science:"#ffdfb8"
};

const segmentsStyle = { cursor: "pointer" };

export default function DonutChart({
  computedStats,
  selected,
  setSelectedArc,
}) {
  return (
    <PieChart
      data={Object.keys(computedStats.subjectStats).map((stat) => ({
        title: computedStats.subjectStats[stat].name,
        value: computedStats.subjectStats[stat].screenTime || 10,
        color: computedStats.subjectStats[stat].screenTime
          ? donutColors[computedStats.subjectStats[stat].code]
          : "grey",
      }))}
      label={({ dataEntry }) => dataEntry.title}
      labelStyle={(index) => ({
        fontSize: 4,
        fontFamily: "Open Sans",
        fontWeight: 600,
      })}
      lineWidth={18}
      paddingAngle={16}
      radius={32}
      labelPosition={120}
      segmentsStyle={(index) => {
        return index === selected
          ? { ...segmentsStyle, strokeWidth: 8, border: "none" }
          : segmentsStyle;
      }}
      onMouseOver={(event, index) => {
        setSelectedArc(index);
      }}
      onMouseOut={() => setSelectedArc(undefined)}
      rounded
    />
  );
}

DonutChart.propTypes = {
  selected: PropTypes.number,
  setSelectedArc: PropTypes.func,
  computedStats: PropTypes.object,
};
