import {PrimaryTypographyHeading4} from "components/typography/PrimaryTypography";
import {AppStoreBadge, GooglePlayBadge} from "./StoreBadge";
import {styled} from "@mui/material/styles";

const DownloadLinksContainer = styled("div")`
  width: 100%;
  padding: 40px 0;
`;

const DownloadLinksTitle = styled(PrimaryTypographyHeading4)`
	margin-bottom: 30px;
`;

function DownloadLinks() {
	return (
		<DownloadLinksContainer>
			<DownloadLinksTitle align={"center"} gutterBottom={true} sx={{fontWeight: "800"}}>
				Get Kutuki
			</DownloadLinksTitle>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<GooglePlayBadge />
				<AppStoreBadge />
			</div>
		</DownloadLinksContainer>
	);
}

export default DownloadLinks;
