import PropTypes from "prop-types";

const SxPropType = PropTypes.oneOfType([
	PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.object,
			PropTypes.bool,
		]),
	),
	PropTypes.func,
	PropTypes.object,
]);

export {
	SxPropType,
}
