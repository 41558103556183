import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	signInWithPhoneNumber,
	RecaptchaVerifier,
	getIdToken,
	signOut,
} from 'firebase/auth';
import { getApp } from './client';

function getAuthInstance(appName) {
	const app = getApp(appName);
	return getAuth(app);
}

async function googleSignIn(appName) {
	const provider = new GoogleAuthProvider();

	const auth = getAuthInstance(appName);
	
	try {
		const result = await signInWithPopup(auth, provider);
		
		const credential = GoogleAuthProvider.credentialFromResult(result);
		
		const token = credential.accessToken;
		
		const user = result.user;
		
		return {
			token,
			user
		};
	}	catch (e) {
		console.error(`firebase/auth -> signInWithGoogle -> error is ${e.message} ${e.stack}`);
	}
}

async function googleSignOut(appName) {

	const auth = getAuthInstance(appName);
	
	try {
      await signOut(auth);
	}	catch (e) {
		console.error(`firebase/auth -> googleSignOut -> error is ${e.message} ${e.stack}`);
	}
}

/*
Keeping this commented code as a fallback in case the SignInUsingPhone
approach doesn't work.

async function signInWithPhone(appName, verifier, phone) {
	const auth = getAuthByAppName(appName);
	try {
		return await signInWithPhoneNumber(auth, phone, verifier);
	} catch (e) {
		console.error(`firebaseAuthentication -> signInWithPhone -> error is ${e.message} ${e.stack}`);
	}
}

async function validateOTP(confirmationResult, OTP) {
	try {
		const result = confirmationResult.confirm(OTP);
		
		return {
			user: result.user,
		}
	} catch (e) {
		console.error(`firebaseAuthentication -> validateOTP -> error is ${e.message} ${e.stack}`);
	}
}

function getRecaptchaVerifier(auth) {
	return new RecaptchaVerifier(auth, 'sign-in-button', {
		'size': 'invisible',
		'callback': () => {},
	});
}
*/

class PhoneSignIn {
	#confirmationResult = null;
	get confirmationResult() {
		return this.#confirmationResult;
	}
	
	#recaptchaVerifier = null;
	get recaptchaVerifier() {
		return this.#recaptchaVerifier;
	}
	
	#auth = null;
	
	init() {
		this.#auth = getAuthInstance();
	}
	
	async sendOTP(phone) {
		try {
			this.#confirmationResult = await signInWithPhoneNumber(
				this.#auth,
				phone,
				this.#recaptchaVerifier,
			);
			
			return this.#confirmationResult;
		} catch (e) {
			console.error(`firebaseAuthentication -> signInWithPhone -> error is ${e.message} ${e.stack}`);
			
			throw new Error(`Please check your phone number and try again.`);
		}
	}
	
	async validateOTP(OTP) {
		if (!this.#confirmationResult) {
			throw new Error(`validateOTP -> confirmation result not is null/ undefined`);
		}
		
		try {
			const result = await this.#confirmationResult.confirm(OTP);
			// resetting the confirmation result
			this.#confirmationResult = null;
			
			return {
				user: result.user,
				token: getIdToken(result.user),
			}
		} catch (e) {
			console.error(`firebaseAuthentication -> validateOTP -> error is ${e.message} ${e.stack}`);
			
			throw new Error(`Please check your phone number or OTP and try again`);
		}
	}
	
	getRecaptchaVerifier(auth) {
		if(!auth) {
			auth = this.#auth;
		}
		this.#recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
			'size': 'invisible',
			'callback': () => {},
		});

		return this.#recaptchaVerifier;
	}
	
	resetRecaptchaVerifier() {
		this.#recaptchaVerifier?.clear();
		window?.recaptchaVerifier?.clear();
	}
}

const phoneSignIn = new PhoneSignIn();

export {
	getAuthInstance,
	phoneSignIn,
	googleSignIn,
	googleSignOut
}
