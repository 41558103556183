import MuiDialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import {SxPropType} from "./commonTypes";

/*
 * Dialog Title Component
 */
function DialogTitle({ children, ...props }) {
	return (
		<MuiDialogTitle {...props}>
			{children}
		</MuiDialogTitle>
	);
}

DialogTitle.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	sx: SxPropType,
};

DialogTitle.defaultProps = {};

/*
 * Dialog Actions Component
 */
function DialogActions({ children, ...props }) {
	return (
		<MuiDialogActions {...props}>
			{children}
		</MuiDialogActions>
	);
}

DialogActions.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	disableSpacing: PropTypes.bool,
	sx: SxPropType,
};

DialogActions.defaultProps = {
	disableSpacing: false,
};

/*
 * Dialog Content Component
 */
function DialogContent({ children, ...props }) {
	return (
		<MuiDialogContent {...props}>
			{children}
		</MuiDialogContent>
	);
}

DialogContent.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	dividers: PropTypes.bool,
	sx: SxPropType,};

DialogContent.defaultProps = {
	dividers: false,
};

/*
 * Dialog Component
 */
function Dialog({ children, ...props }) {
	return (
		<MuiDialog {...props}>
			{children}
		</MuiDialog>
	);
}

Dialog.propType = {
	open: PropTypes.bool.isRequired,
	children: PropTypes.node,
	classes: PropTypes.object,
	disableEscapeKeyDown: PropTypes.bool,
	fullScreen: PropTypes.bool,
	fullWidth: PropTypes.bool,
	maxWidth: PropTypes.oneOfType([
		PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
		PropTypes.bool,
		PropTypes.string,
	]),
	onClose: PropTypes.func,
	scroll: PropTypes.oneOf([ 'body', 'paper' ]),
	sx: SxPropType,
};

Dialog.defaultProps = {
	disableEscapeKeyDown: false,
	fullScreen: false,
	fullWidth: false,
	scroll: 'paper',
};

export default Dialog;

export {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
}
