import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics';
import { getApp } from './client';

class FirebaseAnalytics {
	#analytics;
	
	init() {
		this.#analytics = getAnalytics(getApp());
	}
	
	async trackEvent(name, payload = {}) {
		try {
			return logEvent(this.#analytics, name, payload);
		} catch (e) {
			console.error(`FirebaseAnalytics.trackEvent -> error triggering the event ${name} with payload ${JSON.stringify(payload)}`);
			console.error(`FirebaseAnalytics.trackEvent -> error is ${e.message} ${e.stack}`);
		}
	}
	
	async trackUserProps(props) {
		try {
			return setUserProperties(this.#analytics, props);
		} catch (e) {
			console.error(`FirebaseAnalytics.trackUserProps -> error setting the user props ${JSON.stringify(props)}`);
			console.error(`FirebaseAnalytics.trackUserProps -> error is ${e.message} ${e.stack}`);
		}
	}
	
	async trackUserID(userId) {
		try {
			return setUserId(this.#analytics, userId);
		} catch (e) {
			console.error(`FirebaseAnalytics.trackUserID -> error setting the user Id ${userId}`);
			console.error(`FirebaseAnalytics.trackUserID -> error is ${e.message} ${e.stack}`);
		}
	}
}

const firebaseAnalytics = new FirebaseAnalytics();

export default firebaseAnalytics;

