import { Dialog, DialogContent } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import StarsEarnedDialog from "./StarsEarnedDialog";
import { saveUserActivity } from "features/insights/services/insightsService";
import PropTypes from "prop-types";
import { trackVideoWatchedEvent } from "../features/themes/services/themeService";

export default function VideoPlayer(props) {
  const [isStarsEarnedShown, setIsStarsEarnedShown] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showStarsEarnedDialog, setShowStarsEarnedDialog] = useState(false);
  const [totalDuration, setTotalDuration] = useState(null);

  useEffect(() => {
    setIsStarsEarnedShown(false);
  }, [props.url]);

  const handleProgress = (progress) => {
    if (progress.played * 100 >= 99 && !isStarsEarnedShown) {
      setIsPlaying(false);
      const audio = new Audio("https://cdn.kutuki.in/app-vo/success.mp3");
      audio.loop = false;
      audio.play();
      handleShowStarsEarnedDialog();
      saveUserActivity(getUserActivityData());
      trackVideoWatchedEvent({
        id: props?.activity?.id,
        title: props.activity?.title,
        themeName: props.theme.title,
        themeId: props.theme.id,
        themePosition: props.theme?.displayPosition || 0,
        skippedPercentage: 0,
        displayPosition: props.activity?.displayPosition ?? 0,
        duration:totalDuration,
        percentage: 100,
      })
    }
  };

  const handleDuration = (duration) => {
    setTotalDuration(duration);
  };

  const getUserActivityData = () => {
    return {
      event: "video_watched",
      entityId: props.activity.id,
      entityType: "video",
      parentId: props.theme.id,
      parentType: "theme",
      percentageCompleted: 100,
      starsEarned: 1,
      duration: totalDuration,
    };
  };

  const handleShowStarsEarnedDialog = () => {
    setIsStarsEarnedShown(true);
    setShowStarsEarnedDialog(true);
  };

  const handleHideStarsEarnedDialog = () => {
    setShowStarsEarnedDialog(false);
    setIsPlaying(true);
  };

  return (
    <>
      <ReactPlayer
        url={props.url}
        controls={props.showControls}
        light={props.lightMode}
        width={"inherit"}
        height={"100%"}
        playing={isPlaying}
        onProgress={handleProgress}
        onDuration={handleDuration}
      />
      <Dialog
        open={showStarsEarnedDialog}
        onClose={handleHideStarsEarnedDialog}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: "transparent",
            m: 0,
            p: 0,
          }}
        >
          <StarsEarnedDialog
            handleHideStarsEarnedDialog={handleHideStarsEarnedDialog}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
  showControls: PropTypes.bool,
  light: PropTypes.bool,
  theme: PropTypes.object,
  activity: PropTypes.object,
};
