import React from "react";
import { TextField } from "@mui/material";
import PropTypes from 'prop-types';

export default function TextInput(props) {
  return (
    <TextField
      sx={props.sx}
      classes={props.classes}
			className={props.className}
      label={props.label}
      variant={props.variant}
      value={props.value}
      onChange={props.onChange}
      inputProps={props.inputProps}
      placeholder={props.placeholder}
      name={props.name}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
      type={props.type}
      maxLength= {2}
      disabled={props.disabled}
    />
  );
}

TextInput.propTypes = {
	classes: PropTypes.object,
	inputProps: PropTypes.object,
	label: PropTypes.node,
	onChange: PropTypes.func,
	sx: PropTypes.any,
	value: PropTypes.any,
	variant: PropTypes.oneOf([ 'filled', 'outlined', 'standard' ]),
  placeholder: PropTypes.string,
  name:PropTypes.string,
  error:PropTypes.bool,
  required:PropTypes.bool,
  helperText:PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool
};
