import { Backdrop } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info";
import { SecondaryTypographyText } from "components/typography/SecondaryTypography";
import sessionHelper from "utils/sessionHelper";
import { green } from "@mui/material/colors";
import confettiBg from "assets/svg/confetti.svg";
import checkIcon from "assets/svg/check-icon.svg";
import crossIcon from "assets/svg/cross-icon.svg";
import paymentDialogBg from "assets/svg/payment-dialog-bg.svg";
import ContainedButton from "components/buttons/ContainedButton";

const DIALOG_HEIGHT = "595px";

const DIALOG_WIDTH = "366px";

const DialogContainer = styled("div")`
  width: ${DIALOG_WIDTH};
  height: ${DIALOG_HEIGHT};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

const DialogTitleContainer = styled("div")`
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  line-clamp: 2;
  text-overflow: ellipsis;
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translate(-50%, -50%);
  color: ${green[100]};
`;

const DialogSubTitleContainer = styled("div")`
  position: absolute;
  left: 50%;
  top: 220px;
  color: white;
  font-weight: 700;
  font-style: italic;
  font-size: 14px;
  text-align: center;
  max-lines: 2;
  text-overflow: ellipsis;
  transform: translate(-50%, -50%);
  text-shadow: 2px 4px 4px rgba(46;91;173;0.6);
`;

const DottedLineContainer = styled("div")`
  position: absolute;
  border-bottom: 4px dotted black;
  height: 5px;
  top: 180px;
  left: 50%;
  width: 310px;
  transform: translate(-50%, -50%);
`;

const ConfettiContainer = styled("div")`
  width: 320px;
  height: 595px;
  position: absolute;
  align-self: center;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PaymentInfoContainer = styled("div")`
  position: absolute;
  left: 10%;
  top: 82%;
  width: 80%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const InfoText = styled(SecondaryTypographyText)`
  font-size: 12px;
  color: ${green[100]};
  max-lines: 3;
  font-weight: 900;
  text-overflow: ellipsis;
  text-shadow: 4px 4px 4px rgba(46, 91, 173, 0.6);
`;

const PaymentStatusIconContainer = styled("div")`
  position: absolute;
  top: 48px;
  left: 50%;
  width: 72px;
  height: 72px;
  border-radius: 100px;
  align-items: center;
  display: flex;
  transform: translate(-50%, -50%);
`;

const PaymentStatusIcon = styled("img")`
  margin: 0 auto;
  color: white;
  font-size: 74px;
`;

const DialogImage = styled("img")`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: ${DIALOG_WIDTH};
  height: ${DIALOG_HEIGHT};
  border-radius: 10px;
`;

const DialogActionButton = styled(ContainedButton)`
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  top: 92%;
`;

const PaymentStatus = ({ isSuccess }) => (
  <PaymentStatusIconContainer>
    {isSuccess ? (
      <PaymentStatusIcon src={checkIcon} alt="Tick" />
    ) : (
      <PaymentStatusIcon src={crossIcon} alt="Cross" />
    )}
  </PaymentStatusIconContainer>
);

const Confetti = ({ isSuccess }) => (
  isSuccess ? (
    <ConfettiContainer>
      <img
        src={confettiBg}
        alt="bg"
        style={{
          width: "320px",
          height: DIALOG_HEIGHT,
        }}
      />
    </ConfettiContainer>
  ) : null
);

export default function TransactionStatusDialog({
  title,
  subtitle,
  imageUrl,
  success,
  btnText,
  onButtonClicked,
}) {
  
  const loginIdentifier = sessionHelper.phone || sessionHelper.email;
  
  return (
    <Backdrop open={true}>
      <DialogContainer>
        <img
          src={paymentDialogBg}
          height={DIALOG_HEIGHT}
          width={DIALOG_WIDTH}
          alt="bg"
        />
        <Confetti isSuccess={success} />
        <DialogImage alt="..." src={imageUrl} />
        <PaymentStatus isSuccess={success} />
        <DialogTitleContainer>{title}</DialogTitleContainer>
        <DottedLineContainer />
        <DialogSubTitleContainer>{subtitle}</DialogSubTitleContainer>
        <PaymentInfoContainer>
          <InfoIcon
            sx={{ fontSize: 22, color: "green", fill: "#69CC0C", mr: 1 }}
          />
          <InfoText>
            {`Please login with ${loginIdentifier} on the Kutuki app and have a wonderful Kutuki
            experience`}
          </InfoText>
        </PaymentInfoContainer>
        <DialogActionButton
          onClick={onButtonClicked}
          color={success ? "success" : "error"}
          fullWidth={false}
        >
          {btnText || "Done"}
        </DialogActionButton>
      </DialogContainer>
    </Backdrop>
  );
}
