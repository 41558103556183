import MuiAccordion from '@mui/material/Accordion';
import PropTypes from 'prop-types';

function Accordion({ children, ...props }) {
	return (
		<MuiAccordion {...props}>
			{children}
		</MuiAccordion>
	)
}

Accordion.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.object,
	defaultExpanded: PropTypes.bool,
	disabled: PropTypes.bool,
	disableGutters: PropTypes.bool,
	expanded: PropTypes.bool,
	onChange: PropTypes.func,
	sx: PropTypes.any,
};

Accordion.defaultProps = {
	defaultExpanded: false,
	disabled: false,
	disableGutters: false,
	expanded: false,
};

export default Accordion;

