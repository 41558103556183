import {
	getFirestore,
	collection,
	doc,
	query,
	getDoc,
	getDocs,
	where
}from "firebase/firestore";
import {getApp} from "./client";

function getFirestoreByApp(app) {
	return getFirestore(app);
}

function getCollectionRef(db, collectionName) {
	return collection(db, collectionName);
}

async function getDocument({ appName, collectionName, id }) {
	const app = getApp(appName);
	
	const db = getFirestoreByApp(app);
	
	const documentRef = doc(db, collectionName, id);
	
	return getDoc(documentRef);
}

const OPERATORS = {
	eq: '==',
	gt: '>',
	gte: '>=',
	lt: '<',
	lte: '<=',
	arrayContains: 'array-contains',
	arrayContainsAny: 'array-contains-any',
	in: 'in',
	notIn: 'not-in'
};

function _processCondition(condition) {
	const operator = OPERATORS[condition.operator];
	
	if (!operator) {
		throw new Error(`Invalid operator ${condition.operator}`);
	}
	
	if (!condition.field) {
		throw new Error(`Invalid field name ${condition.field}`);
	}
	
	if (!condition.value) {
		throw new Error(`Invalid value ${condition.value}`);
	}
	
	return where(condition.field, operator, condition.value);
}

async function getDocuments({ appName, collectionName, conditions, order = 'asc', by = '', limit }) {
	const app = getApp(appName);
	
	const db = getFirestoreByApp(app);
	
	const collectionRef = getCollectionRef(db, collectionName);
	
	const _query = query(
		collectionRef,
		...conditions.map(_processCondition),
	)
	
	return await getDocs(_query);
}

function snapshotToArray(snapshot) {
	const data = [];
	
	snapshot.forEach(doc => data.push({
		_id: doc.id,
		...doc.data(),
	}));
	
	return data;
}

function snapshotToObject(snapshot) {
	const data = {};
	
	snapshot.forEach(doc => {
		data[doc.id] = {
			_id: doc.id,
			...doc.data(),
		};
	});
	
	return data;
}

export {
	getFirestoreByApp,
	getCollectionRef,
	getDocument,
	getDocuments,
	snapshotToArray,
	snapshotToObject,
}
