import React from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { styled } from '@mui/material/styles';

const BorderedContainer = styled('div')({
    display: 'inline-block',
    border: '4px solid',
    color: '#C3EEFF',
    borderRadius: '10px',
    width: "100%"
  });

const NonBorderedContainer = styled('div')({
    display: 'inline-block',
    color: '#C3EEFF',
    width: "100%"
  });

function PhoneNumberInputBordered({ onChange, phone, sx, disabled }) {
  
  return (
    <BorderedContainer>
      <PhoneInput
        style={{
          ...sx
        }}
        forceDialCode={true}
        defaultCountry='in'
        onChange={onChange}
        value={phone}
        disabled={disabled}
        disableFormatting={true}
        inputStyle={
          {
            width:"100%",
            fontFamily:"Fredoka",
            fontWeight: 900
          }
        }
      />
    </BorderedContainer>
  );
}

function PhoneNumberInput(props) {
  
  return (
    <NonBorderedContainer>
      <PhoneInput
        style={{
          ...props.sx
        }}
        name={props.name}
        forceDialCode={true}
        defaultCountry='in'
        onChange={props.onChange}
        value={props.phone}
        disabled={props.disabled}
        disableFormatting={true}
        inputStyle={
          {
            width:"100%",
            fontFamily:"Fredoka",
            fontWeight: 900
          }
        }
      />
    </NonBorderedContainer>
  );
}

export {
  PhoneNumberInput,
  PhoneNumberInputBordered
}
