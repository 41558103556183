import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "@emotion/styled";

const CarouselContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: "center";
  width: auto;
  height: "auto";
`;

const CarouselImage = styled("img")`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Carousel = ({ images, classes, autoplay, duration }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalId = useRef(null);

  const nextSlide = useCallback(() => {
    const index =
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;
    setCurrentImageIndex(index);
  }, [currentImageIndex, images]);

  useEffect(() => {
    if (autoplay) {
      intervalId.current = setInterval(() => nextSlide(), duration || 2000);
      return () => clearInterval(intervalId.current);
    }
  }, [autoplay, duration, nextSlide]);

  return (
    <CarouselContainer>
      <CarouselImage
        src={images[currentImageIndex]}
        alt={`login-carousel-images-${currentImageIndex}`}
      />
    </CarouselContainer>
  );
};

export default Carousel;
