import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';

function AccordionDetails({ children, ...props }) {
	return (
		<MuiAccordionDetails {...props}>
			{children}
		</MuiAccordionDetails>
	);
}

AccordionDetails.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	sx: PropTypes.any,
};

AccordionDetails.defaultProps = {};

export default AccordionDetails;
