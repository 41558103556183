const PARAM_INCLUSION_SET = new Set(["utm_", "ac", "req"]);

class QueryParamStore {
  #params = {};

  constructor() {
    const params = new URLSearchParams(window.location.search);
    for (const [key, value] of params.entries()) {
      for (const param of PARAM_INCLUSION_SET) {
        if (key.startsWith(param)) {
          this.#params[key] = value;
          break;
        }
      }
    }
  }

  queryParams(url) {
    const _queryParams = new URLSearchParams(this.#params).toString();
    return url.includes("?")
      ? `${url}&${_queryParams}`
      : `${url}?${_queryParams}`;
  }

  getParam(name) {
    return this.#params[name];
  }
}

const queryParamStore = new QueryParamStore();

export { queryParamStore };
