import { useState, useEffect,forwardRef, useImperativeHandle } from "react";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { SecondaryTypographyText } from "../../../components/typography/SecondaryTypography";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { PrimaryTypographyText } from "../../../components/typography/PrimaryTypography";
import sessionHelper from "../../../utils/sessionHelper";

const CurrentSubscriptionContainer = styled("div")`
  display: flex;
  margin: auto;
  height: 10rem;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    width: 44%;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const CurrentSubscriptionCard = forwardRef((props, ref) => {
  
  const [subscription, setSubscription] = useState({});

  useImperativeHandle(ref, () => ({
    updateChildState() {
      setCardDetails();
    }
  }));

  const setCardDetails = ( ) => {
    const currentDate = new Date();
    const subscriptionStartDate = new Date(sessionHelper.subscriptionStartDate);
    const expiryDate = new Date(sessionHelper.subscriptionExpiry);
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    
    const daysLeft = Math.round((expiryDate - currentDate) / oneDay);
    const planTotalDuration = Math.round((expiryDate - subscriptionStartDate) / oneDay);

    const progressValue = ((daysLeft) / planTotalDuration) * 100;

    //This is done because we were getting extra one day on Date parsing;
    //eg 2023-11-23 23:59:59.000 -> 2023-11-24

    expiryDate.setDate(expiryDate.getDate() -1);

    setSubscription({
      progress: progressValue < 0 ? 0 : progressValue ,
      daysLeft: daysLeft > 0 ? daysLeft - 1 : 0,
      expiresOn: expiryDate.toLocaleDateString('en-IN', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Asia/Kolkata' }),
      currentPlan: sessionHelper.subscriptionPlan,
    });
  }
  
  useEffect(() => {
    setCardDetails();
  }, []);
  
  return (
    <CurrentSubscriptionContainer>
      <Box
        m="auto"
        sx={{
          width: "100%",
          backgroundColor: "white",
          height: "100%",
          borderRadius: "15px",
          display: "flex",
          boxShadow: 10,
          flexDirection: "column",
        }}
      >
        <Grid
          container
          height="100%"
          flexDirection="row"
          pl={2}
          justifyContent="space-evenly"
        >
          <Grid item md={4} xs={4} sm={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgressWithLabel
                value={subscription.progress}
                color="warning"
                days={subscription.daysLeft}
              />
            </Box>
          </Grid>
          <Grid item md={8} xs={8} sm={8}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height="100%"
            >
              <SecondaryTypographyText
                sx={{ fontSize: "1.2rem", fontWeight: 900 }}
              >
                Current Plan
              </SecondaryTypographyText>
              <PrimaryTypographyText sx={{ color: "grey", fontSize: "1.1rem", }}>
                {subscription.currentPlan}
              </PrimaryTypographyText>
              <PrimaryTypographyText
                sx={{
                  maxLines: 2,
                  overflow: "ellipsis",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: 800,
                  m: 1,
                }}
              >
                {`plan active till ${subscription.expiresOn}`}
              </PrimaryTypographyText>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CurrentSubscriptionContainer>
  );
});

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size="7rem"
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: props.value >= 25 ? "#009B5A" : "#FF2020",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size="7rem"
        thickness={4}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <SecondaryTypographyText
          variant="caption"
          component="div"
          sx={{
            fontSize: "20px",
            fontWeight: 900,
            color: props.value >= 25 ? "#009B5A" : "#FF2020",
          }}
        >{`${Math.round(props.days)}`}</SecondaryTypographyText>
        <SecondaryTypographyText
          variant="caption"
          component="div"
          color="text.secondary"
        >{`days left`}</SecondaryTypographyText>
      </Box>
    </Box>
  );
}

CurrentSubscriptionCard.propTypes = {

};

CurrentSubscriptionCard.defaultProps = {

};


export default CurrentSubscriptionCard;