import styled from "@emotion/styled";
import {
  Box,
  Grid,
  Link,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircularStaticProgress from "components/CircularStaticProgress";
import PinPut from "components/inputs/PinPut";
import OutlinedButton from "components/buttons/OutlinedButton";
import TextButton from "components/buttons/TextButton";
import { PhoneNumberInputBordered } from "components/inputs/PhoneNumberInput";
import { PrimaryTypographySubtitle } from "components/typography/PrimaryTypography";
import { PrimaryTypographyHeading6 } from "components/typography/PrimaryTypography";
import logo from "assets/images/logo.png";
import googleIcon from "assets/svg/google-icon.svg";
import React, { useEffect, useRef, useState } from "react";
import { getStaticOtp } from "features/common/services/userService";
import useSnackBar from "hooks/useSnackbar";
import { useNavigation } from "../../Router";
import { googleSignIn, phoneSignIn } from "utils/firebase/auth";
import {
  getSignInPageConfig,
  signIn,
} from "features/authenticate/services/authService";
import { useLoadingOverlay } from "components/LoadingOverlay";
import Carousel from "components/Carousel";
import { SecondaryTypographyText } from "components/typography/SecondaryTypography";

const LoginPageContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LoginOptionsContainer = styled("div")`
  margin: 0 auto;
`;

export default function Login() {
  const showSnackBar = useSnackBar();
  const { navigateTo } = useNavigation();
  const recaptchaRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const [showOtpFields, setShowOtpFields] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [signInConfig, setSignInConfig] = useState(null);

  const [otpError, setOtpError] = useState(false);

  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    phoneSignIn.init();
    phoneSignIn.resetRecaptchaVerifier();
    phoneSignIn.getRecaptchaVerifier();

    getSignInPageConfig()
      .then((_config) => setSignInConfig(_config))
      .catch((_) => {});
  }, []);

  const onOtpChange = (value) => {
    setOtp(value);
    if (otpError) setOtpError(false);
  };

  const onSentOtpPressed = async () => {
    try {
      if (phone.length < 9) {
        showSnackBar("Please check your phone and try again");
        return;
      }
      showLoadingOverlay();
      const staticOtpData = await getStaticOtp(phone);
      if (staticOtpData?.isRegistered) {
        setIsRegistered(true);
        setShowOtpFields(true);
      } else {
        await phoneSignIn.sendOTP(phone);
        setShowOtpFields(true);
      }
      hideLoadingOverlay();
    } catch (error) {
      hideLoadingOverlay();
      showSnackBar(error.message);
    }
  };

  const resetRecaptcha = () => {
    if (window.recaptchaVerifier) {
      recaptchaRef.current.innerHTML = `<div id="sign-in-button"></div>`;
      phoneSignIn.resetRecaptchaVerifier();
    }
  };

  const onValidateOtpPressed = async () => {
    if (otp.length < 6) {
      showSnackBar("Please check your OTP and try again.");
      return;
    }

    try {
      showLoadingOverlay();
      if (isRegistered) {
        const signInResponse = await signIn({
          phone,
          otp,
          isStaticOTP: isRegistered,
        });
        if (!signInResponse.isRegistered) {
          navigateTo("/auth/register", { state: { phone: phone } });
        } else {
          navigateTo("/");
        }
      } else {
        const result = await phoneSignIn.validateOTP(otp);
        const signInResponse = await signIn({
          phone,
          isStaticOTP: isRegistered,
          firebaseToken: result.user.accessToken,
          firebaseUid: result.user.uid,
        });
        if (signInResponse.isRegistered) {
          navigateTo("/");
        } else {
          navigateTo("/auth/register", { state: { phone: phone } });
        }
      }
      hideLoadingOverlay();
    } catch (error) {
      console.log(error);
      setOtpError(true);
      showSnackBar("Please check your OTP and try again.");
      hideLoadingOverlay();
    }
  };

  const onEditNumberPressed = () => {
    setIsRegistered(false);
    setShowOtpFields(false);
    if (recaptchaRef.current.children.length) {
      resetRecaptcha();
    }
  };

  const onResendOtpPressed = () => {
    if (recaptchaRef.current.children.length) {
      resetRecaptcha();
    }
    phoneSignIn.sendOTP(phone);
  };

  return (
    <LoginPageContainer>
      <div ref={recaptchaRef}>
        <div id="sign-in-button"></div>
      </div>

      <Grid item xs={12} md={12} display="flex">
        <div style={{ margin: !isMobile ? "10px" : "10px auto" }}>
          <img
            style={{
              width: !isMobile ? "10vw" : "100px",
            }}
            src={logo}
            alt="Kutuki"
          />
        </div>
      </Grid>

      <Box
        borderRadius={1}
        display={"flex"}
        padding={1}
        border={"1px solid grey"}
        flexDirection={isMobile ? "column" : "row"}
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          alignItems: "center",
          width: "90vw",
          height: "auto",
          margin: "0 auto",
        }}
      >
        <Grid container spacing={2} columns={{ xs: 12, md: 12 }}>
          <Grid item md={6} sm={12} xs={12} style={{ margin: "0px" }}>
            <div
              style={{
                height: isMobile ? "180px" : "100%",
                width: isMobile ? "180px" : "100%",
                margin: "0 auto"
              }}
            >
              {signInConfig ? (
                <Carousel
                  images={signInConfig.slides}
                  autoplay={true}
                  duration={5000}
                />
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "0 auto",
              minHeight: "45vw"
            }}
          >
            {showOtpFields ? (
              <ValidateOtpContainer
                onValidateOtpPressed={onValidateOtpPressed}
                phoneNumber={phone}
                onOtpChange={onOtpChange}
                otpError={otpError}
                isStatic={isRegistered}
                onPressEditNumber={onEditNumberPressed}
                onResendOtpPressed={onResendOtpPressed}
                isMobile={isMobile}
              />
            ) : (
              <SendOtpContainer
                onSentOtpPressed={onSentOtpPressed}
                setPhone={setPhone}
                phoneNumber={phone}
                signInConfig={signInConfig}
                isMobile={isMobile}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </LoginPageContainer>
  );
}

function SendOtpContainer({
  onSentOtpPressed,
  setPhone,
  phoneNumber,
  signInConfig,
  isMobile,
}) {
  const { navigateTo } = useNavigation();
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => setIsLoading(true);

  const hideLoader = () => setIsLoading(false);

  const signWithGoogle = async () => {
    showLoader();
    try {
      const userData = await googleSignIn();
      if (userData?.user) {
        const signInResponse = await signIn({
          phone: userData.user.phoneNumber,
          isStaticOTP: false,
          firebaseToken: userData.user.accessToken,
          firebaseUid: userData.user.uid,
          mode: "social",
          email: userData.user.email,
        });
        if (!signInResponse.isRegistered) {
          navigateTo("/auth/register", {
            state: { phone: phoneNumber, email: userData.user.email },
          });
        } else {
          navigateTo("/");
        }
        hideLoader();
      }
    } catch (error) {
      hideLoader();
    }
  };

  function onChange(phone) {
    setPhone(phone);
  }

  return (
    <LoginOptionsContainer
      style={{
        paddingLeft: isMobile ? "20px" : "20px",
        paddingRight: isMobile ? "20px" : "20px",
      }}
    >
      <Grid container columns={{ xs: 12, md: 12 }}>
        <Box
          borderRadius={4}
          display={"flex"}
          spacing={1}
          margin={0}
          flexDirection="column"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {signInConfig ? (
            <PrimaryTypographyHeading6
              sx={{
                fontFamily: "Fredoka",
                m: "auto",
                fontWeight: 900,
              }}
            >
              {signInConfig.signUpTextWeb}
            </PrimaryTypographyHeading6>
          ) : (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "1.2rem", width: "100%", pt: 2 }}
            />
          )}
          {signInConfig ? (
            <PrimaryTypographySubtitle
              sx={{
                fontFamily: "Open Sans",
                m: "auto",
                color: "#3092B2",
                fontSize: "12px",
                fontWeight: 600,
                pb: 2
              }}
            >
              {signInConfig.signUpSubText}
            </PrimaryTypographySubtitle>
          ) : (
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "1rem", width: "70%", pb: 2 }}
            />
          )}
          <PrimaryTypographyHeading6
            sx={{
              fontFamily: "Fredoka",
              mr: "auto",
              ml: 1,
              fontWeight: 900,
            }}
          >
            Email
          </PrimaryTypographyHeading6>
          {!isLoading ? (
            <OutlinedButton
              sx={{
                fontFamily: "Fredoka",
                backgroundColor: "#FFFFFF",
                borderRadius: 3,
                border: "4px solid #C3EEFF",
                p: 0.8,
                fontSize: "16px",
                fontWeight: 900,
                color: "black",
                "&:hover": {
                  border: "4px solid #C3EEFF",
                },
              }}
              onClick={signWithGoogle}
              startIcon={<img src={googleIcon} alt="Google" height={24} />}
            >
              Sign in with google
            </OutlinedButton>
          ) : (
            <CircularStaticProgress />
          )}
          <PrimaryTypographyHeading6
            sx={{
              fontFamily: "Fredoka",
              m: "auto",
              fontWeight: 900,
            }}
          >
            Or
          </PrimaryTypographyHeading6>

          <PrimaryTypographyHeading6
            sx={{
              fontFamily: "Fredoka",
              mr: "auto",
              fontWeight: 900,
              ml: 1,
            }}
          >
            Phone
          </PrimaryTypographyHeading6>
          <PhoneNumberInputBordered
            onChange={onChange}
            phone={phoneNumber}
            sx={{
              "--react-international-phone-border-radius": "5px",
              "--react-international-phone-border-color": "#C3EEFF",
              "--react-international-phone-height": "44px",
            }}
          />
          <TextButton
            sx={{
              fontFamily: "Fredoka",
              fontSize: "16px",
              fontWeight: 900,
              color: "white",
              backgroundColor: "#F24954",
              width: "150px",
              borderRadius: "10px",
              mt: 2,
              "&:hover": {
                backgroundColor: "#F24954",
              },
            }}
            onClick={onSentOtpPressed}
          >
            Send OTP
          </TextButton>

          <SecondaryTypographyText
            sx={{
              fontFamily: "Open Sans",
              m: "auto",
              pt: 2,
              pb: 2,
              maxLines: 2,
              textOverflow: "ellipsis",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            By continuing you agree to our{" "}
            <Link target="_blank" href="https://www.kutuki.in/terms-of-use-2">
              Terms of Use
            </Link>{" "}
            and acknowledge that you have read our{" "}
            <Link target="_blank" href="https://www.kutuki.in/privacy-policy">
              Privacy Policy
            </Link>
          </SecondaryTypographyText>
        </Box>
      </Grid>
    </LoginOptionsContainer>
  );
}

const Timer = ({ onResendPressed }) => {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {seconds > 0 ? (
        <PrimaryTypographySubtitle
          sx={{
            fontFamily: "Fredoka",
            fontWeight: 900,
            maxLines: 2,
            m: "0 auto",
            textOverflow: "ellipsis",
            textAlign: "center",
            color: "#006086",
          }}
        >
          {`Resend OTP in ${seconds} seconds`}
        </PrimaryTypographySubtitle>
      ) : (
        <TextButton
          sx={{
            fontFamily: "Fredoka",
            fontWeight: 900,
            fontSize: "16px",
            textOverflow: "ellipsis",
            textAlign: "center",
            textTransform: "none",
            color: "#006086",
          }}
          onClick={onResendPressed}
        >
          Resend OTP
        </TextButton>
      )}
    </div>
  );
};

function ValidateOtpContainer({
  onValidateOtpPressed,
  phoneNumber,
  onOtpChange,
  isStatic,
  onPressEditNumber,
  onResendOtpPressed,
  otpError,
}) {
  const [showTimer, setShowTimer] = useState(true);

  const onClickResendOTP = () => {
    setShowTimer(false);
    onResendOtpPressed();
    setShowTimer(true);
  };

  return (
    <LoginOptionsContainer>
      <Grid container spacing={2} columns={{ xs: 12, md: 12 }}>
        <Grid item xs={12} md={12}>
          <Box
            borderRadius={4}
            display={"flex"}
            spacing={1}
            flexDirection="column"
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              pl: "10px",
              pr: "10px",
            }}
          >
            <PrimaryTypographyHeading6
              sx={{
                fontFamily: "Fredoka",
                m: "auto",
                fontWeight: 900,
                fontSize: "16px",
                pt: 2,
                maxLines: 2,
                textOverflow: "ellipsis",
                textAlign: "center",
                color: "#006086",
              }}
            >
              {`Enter the OTP received on ${phoneNumber}`}
            </PrimaryTypographyHeading6>

            <Box
              sx={{
                mt: 4,
                mb: 4,
              }}
            >
              {" "}
              <PinPut onOtpChange={onOtpChange} error={otpError} />
            </Box>
            <Grid
              container
              direction="row"
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-evenly",
              }}
              spacing={0}
            >
              {!isStatic && showTimer ? (
                <Grid item md={6} sm={6} xs={6}>
                  <Timer onResendPressed={onClickResendOTP} />
                </Grid>
              ) : null}
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  m: "auto",
                }}
              >
                <TextButton
                  sx={{
                    fontFamily: "Fredoka",
                    m: "0 auto",
                    fontWeight: 900,
                    fontSize: "16px",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    textTransform: "none",
                    color: "#006086",
                  }}
                  onClick={onPressEditNumber}
                >
                  Edit Number
                </TextButton>
              </Grid>
            </Grid>

            <TextButton
              sx={{
                fontFamily: "Fredoka",
                fontSize: "16px",
                fontWeight: 900,
                color: "white",
                backgroundColor: "#F24954",
                width: "150px",
                borderRadius: "10px",
                mt: 2,
                mb: 2,
                "&:hover": {
                  backgroundColor: "#F24954",
                },
              }}
              onClick={onValidateOtpPressed}
            >
              Validate OTP
            </TextButton>
          </Box>
        </Grid>
      </Grid>
    </LoginOptionsContainer>
  );
}
