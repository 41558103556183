import { trackEvent, trackGtagEvent } from "utils/analytics";
import { filterContentForLearningLevel } from "utils/content";
import { getDocuments, snapshotToArray } from "utils/firebase/firestore";
import sessionHelper from "utils/sessionHelper";


async function getThemes() {

    const snapshot = await getDocuments({
        appName: "kutuki-secondary",
        collectionName: "theme",
        conditions: [
          { field: "type", operator: "eq", value: "root" },
          { field: "schemaVersions", operator: "arrayContains", value: 3 }
        ],
      });
  
      const result = snapshotToArray(snapshot);
      return result.sort((a,b) => a.displayPosition - b.displayPosition).filter((theme) => !["Rhymes", "Games", "Favourite"].includes(theme.title) );
}

async function getContentMappedToTheme(themeId, hasSubCategories, categoryId) {

    let conditions = [
      {
        field: hasSubCategories ? "type" : "activityType",
        operator: "eq",
        value: hasSubCategories ? "category" : "content",
      },
    ];

    if (!hasSubCategories) {
      conditions.push({ field: "isActive", operator: "eq", value: true });
    }

    const snapshot = await getDocuments({
        appName: "kutuki-secondary",
        collectionName: `theme/${themeId}${!hasSubCategories && categoryId ? `/category/${categoryId}` : ''}/${hasSubCategories ? 'category':'activities'}`,
        conditions,
      });
  
      const result = snapshotToArray(snapshot);
      const filteredContent = filterContentForLearningLevel(result, sessionHelper.learningLevel);
      return filteredContent.sort((a,b) => a.displayPosition - b.displayPosition);
}

async function trackVideoWatchedEvent(data) {
	trackEvent("videoWatched", {
    "contentId": data?.id,
    "contentName": data?.title,
    "contentCategory": data.themeName,
    "contentCategoryId": data.themeId,
    "contentCategoryPosition": data.themePosition,
    "skippedPercentage": data.skippedPercentage,
    "contentPosition": data?.displayPosition ?? 0,
    "totalDuration": data.duration,
    "percentageCompleted": data.percentage,
    "source": "subscription_site"
	}).catch((e) => {
		console?.log(
			`trackVideoWatchedEvent -> error occurred tracking the video watched`,
			{
				code: e.code || "",
				message: e.message,
			});
	});

  trackGtagEvent({type:"event", event:"select_content", payload:{
    "content_id": data?.id,
    "content_type": "video"
	}});

  trackLevelEvent(sessionHelper.starsEarned);

}

const getLevel = (starsEarned) => {
  const levelsMap = {
    5: 1,
    15: 2,
    30: 3,
    50: 4,
    100: 5,
    200: 6,
    400: 7
  };

  return levelsMap[starsEarned];
}

function trackLevelEvent(starsEarned) {

  var level = getLevel(starsEarned);

  var levels = [0, 5, 15, 30, 50, 100, 200, 400];

  if (!levels.includes(starsEarned) || level === undefined)
    return;

  const eventName = "LEVEL_$level";

  trackGtagEvent({type:"event", event:"unlock_achievement", payload:{
    "achievement_id": eventName
	}});
}

export {
    getThemes,
    getContentMappedToTheme,
    trackVideoWatchedEvent
}