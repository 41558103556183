import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@emotion/react";
import { SecondaryTypographyHeading2 } from "components/typography/SecondaryTypography";
import ProductCard from "./components/ProductCard";
import PromoCode from "./components/PromoCode";
import Footer from "./components/Footer";
import TransactionStatusDialog from "./components/TransactionStatusDialog";
import { getProducts } from "features/payments/services/productsService";
import useSnackBar from "hooks/useSnackbar";
import DownloadLinks from "./components/DownloadLinks";
import CurrentSubscriptionCard from "features/payments/components/CurrentSubscriptionCard";
import sessionHelper from "utils/sessionHelper";
import {useLoadingOverlay} from "components/LoadingOverlay";
import {
  loadScript,
  createPayment,
  _trackPaymentFailed,
  getPaymentErrors
} from "./services/paymentsService"
import {getTransactionDialogConfig, redeemCouponService, validateCouponService} from "./services/couponCodeService";
import { getDurationUnit } from "utils/subscription";
import CouponAppliedDialog from "./components/CouponAppliedDialog";

const PlanPageContainer = styled("div")`
  padding: 80px 0px 20px;
  height: 100%;
  position: relative
`;

const PageTitle = styled(SecondaryTypographyHeading2)`
  margin-bottom: 20px;
  margin-top: 2.5rem;
`;

const ProductsListingContainer = styled("div")`
  margin-bottom: 2.5rem;
`;

const DEFAULT_COUPON = {
  "code": "",
  "discountType": "",
  "discountValue": 0,
  "productId": "",
  "price": 0,
  "maxDiscount": 0,
  "discountedPrice": 0,
};

const DEFAULT_TRANSACTION_DIALOG_DATA = {
  visibility: false,
  subTitle: "",
  title: "",
  isSuccess: false,
  image: "https://cdn.kutuki.in/Thumbnails%2Band%2BCovers/Thumbnails/payment-sucessful_dialog.png",
  btnText: "Done"
};

function ProductListing() {
  const theme = useTheme();

  const showSnackBar = useSnackBar();

  const currentSubscriptionRef = useRef();
  
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const [products, setProducts] = useState([]);

  const [dialogsConfig, setDialogsConfig] = useState(null);

  const [coupon, setCoupon] = useState(DEFAULT_COUPON);
  
  const [transactionStatusDialogData, setTransactionStatusDialogData] = useState(DEFAULT_TRANSACTION_DIALOG_DATA);
  
  const [couponAppliedDialogVisibility, setCouponAppliedDialogVisibility] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [razorpayErrorMessages, setRazorpayErrorMessages] = useState(null);

  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const refreshSubscriptionCard = () => {
    currentSubscriptionRef.current.updateChildState();
  }

  useEffect(() => {
    showLoadingOverlay();
    getProducts()
      .then((_products) => {
        setProducts(_products);
        hideLoadingOverlay();
      })
      .catch((_) =>
        showSnackBar(
          "Uh oh! We are unable to get the pricing plans. Please refresh and try again."
        )
      );
    
  	getTransactionDialogConfig()
	    .then((_dialogConfig) => setDialogsConfig(_dialogConfig))
	    .catch((e) =>
        console.log('getTransactionDialogConfig -> error fetching dialogs config', e)
	    );

    getRazorpayErrorMessages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const onProductSelected = (i) => {
    setSelectedIndex(i);
    invalidateCoupon(i);
  };

  async function getRazorpayErrorMessages () {
    const errorMessages = await getPaymentErrors();
    setRazorpayErrorMessages(errorMessages);
  }

  const invalidateCoupon = (productIndex) => {
    if (
      coupon.productId &&
      coupon.discountValue !== 100 &&
      products[productIndex].id !== coupon.productId
    ) {
      setCoupon(DEFAULT_COUPON);
    }
  };

  function onCouponChanged(event) {
    setCoupon({
      ...coupon,
      code: event.target.value.toUpperCase(),
    });
  }
  
  async function onApplyButtonPressed(event) {
    event.preventDefault();
    
    if (!coupon.code) {
      showSnackBar("Please enter the coupon code");
      return;
    }
    
    try {
      const couponData = await validateCouponService(coupon.code);
      
      setCoupon(couponData);
      
      let productIndex;
      
      products.forEach((product, index) => {
        if (product.id === couponData.productId) {
          productIndex = index;
        }
      });

      if (productIndex !== undefined) {
        setSelectedIndex(productIndex);
      }
      
      setCouponAppliedDialogVisibility(true);
    } catch (e) {
      showSnackBar(e.message);
    }
  }
  
  function onRemoveButtonPressed(e) {
    e.preventDefault();
    setCoupon(DEFAULT_COUPON);
  }

  async function onRedeemButtonPressed() {
    try {
      showLoadingOverlay();
      
      const result = await redeemCouponService(coupon.code);
      
      // update the subscription in the session helper
      sessionHelper.setSubscription({ subscription: result.subscription });
  
      refreshSubscriptionCard();
      setTransactionStatusDialogData({
        title: dialogsConfig.couponRedeemedSuccess.title,
        subTitle: dialogsConfig.couponRedeemedSuccess.subTitle,
        isSuccess: true,
        visibility: true,
		    image: dialogsConfig.couponRedeemedSuccess.image,
        btnText: "Done"
      });
    } catch(e) {
      console.error(`onRedeemButtonPressed -> ${e.message}`);
  
      setTransactionStatusDialogData({
        title: dialogsConfig.paymentFailed.title,
        subTitle: e.message,
        isSuccess: false,
        visibility: true,
		    image: dialogsConfig.paymentFailed.image,
        btnText: "Try Again"
      });
    } finally {
      hideLoadingOverlay();
    }
  }
  
  async function postVerifyCb(result) {
    try {

      const product = products[selectedIndex];

      const durationText = `${product.duration.value} ${getDurationUnit(product.duration.unit)}`;

      const _dialogData = {
        visibility: true,
        isSuccess: true,
        title: dialogsConfig.paymentSuccessful.title ?? "Payment Successful",
        subTitle: dialogsConfig?.paymentSuccessful?.subTitle?.replace("%", durationText) ?? "",
        image: dialogsConfig?.paymentSuccessful?.image,
        btnText: "Done"
      };
  
      if (result.error) {
        _dialogData.isSuccess = false;
        _dialogData.title = "Payment Failed";
        _dialogData.btnText = "Try Again"
      }
  
      setCoupon(DEFAULT_COUPON);
      refreshSubscriptionCard();
      hideLoadingOverlay();

      setTransactionStatusDialogData(_dialogData);
    } catch (e) {
      console.error(`postVerifyCb -> ${e.message} ${e.stack}`);
    }
  }
  
  async function onRazorPayModalDismissed() {
    hideLoadingOverlay();
  }

  const isCouponFullyRedeemable = () => {
    return (
      coupon?.discountType === "percentage" && coupon?.discountValue === 100
    );
  };
  
  async function onPayButtonPressed() {
    try {
      showLoadingOverlay();
  
      const res = await loadScript(document, 'https://checkout.razorpay.com/v1/checkout.js');

      if (!res) {
        alert('Uh oh! We are unable to process this payment. Please try again');
        return;
      }

      const product = products[selectedIndex];

      if (!product) {
        showSnackBar("Please choose a product and try again");
        
        return;
      }
      
      const createPaymentResult = await createPayment({
        productId: product.id,
        couponCode: (
          coupon.discountValue > 0 && !isCouponFullyRedeemable()
            ? coupon.code
            : ""
        ),
        onDismiss: onRazorPayModalDismissed,
        postVerifyCb: postVerifyCb,
        productTitle: product.title,
        productDuration: product.durationValue
      });
  
      const paymentObject = new window.Razorpay(createPaymentResult);
      
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        _trackPaymentFailed({
          amount: createPaymentResult.amount/100,
          productName: product.title, // plan title
          paymentMethod: "RAZOR_PAY",
          sourceName: "subscriptionScreen",
          error:
            razorpayErrorMessages[response?.error?.reason] ||
            response?.error?.description,
        });
      });
    } catch (e) {
      console.error(`onPayButtonPressed -> ${e.message}`);
  
      setTransactionStatusDialogData({
        title: dialogsConfig.paymentFailed.title,
        subTitle: e.message,
        isSuccess: false,
        visibility: true,
		    image: dialogsConfig.paymentFailed.image,
        btnText: "Try Again"
      });
    } finally {
      hideLoadingOverlay();
    }
  }

  function onStatusDialogClosed() {
    setTransactionStatusDialogData(DEFAULT_TRANSACTION_DIALOG_DATA);
    setCoupon(DEFAULT_COUPON);
  }

  function isProductCardDisabled(i) {
    return (coupon.code.length > 0 &&
    coupon.productId.length > 0 &&
    coupon.productId !== products[i].id);
  }
  
  function onCouponAppliedDialogClosed() {
    setCouponAppliedDialogVisibility(false);
  }

  return (
    <PlanPageContainer>
      <CurrentSubscriptionCard ref={currentSubscriptionRef} />
      <PageTitle align={"center"}>Plans</PageTitle>
      <ProductsListingContainer>
        <Grid container spacing={2} justifyContent={"center"} p={1}>
          {products.map((product, index) => (
            <Grid item xs={12} md={5} sm={12} key={product.id}>
              <ProductCard
                isExpanded={isMobileDevice ? selectedIndex === index : true}
                isSelected={selectedIndex === index}
                showMaxPrice={true}
                durationUnit={product.duration.unit}
                durationValue={product.duration.value}
                minPrice={product.price.list}
                maxPrice={product.price.max}
                description={product.formattedDescription}
                onProductSelected={() => onProductSelected(index)}
                discountedPrice={
                  coupon?.discountedPrice > 0 && coupon.productId === product.id
                    ? coupon?.discountedPrice
                    : null
                }
                disabled={
                  isProductCardDisabled(index)
                }
              />
            </Grid>
          ))}
        </Grid>
      </ProductsListingContainer>
      <PromoCode
        coupon={coupon.code}
        couponInputDisabled={Boolean(coupon.discountValue)}
        isFullyRedeemable={isCouponFullyRedeemable()}
        onCouponChanged={onCouponChanged}
        onApplyButtonPressed={onApplyButtonPressed}
        onRemoveButtonPressed={onRemoveButtonPressed}
        onRedeemButtonPressed={onRedeemButtonPressed}
        onPayButtonPressed={onPayButtonPressed}
        price={products[selectedIndex]?.price.list}
        discountedPrice={coupon?.discountedPrice}
      />
      <DownloadLinks />
      <Footer />
      {transactionStatusDialogData.visibility ? (
        <TransactionStatusDialog
          success={transactionStatusDialogData.isSuccess}
          title={transactionStatusDialogData.title}
          subtitle={transactionStatusDialogData.subTitle}
          onButtonClicked={onStatusDialogClosed}
          imageUrl={transactionStatusDialogData.image}
          btnText={transactionStatusDialogData.btnText}
        />
      ) : null}
      <CouponAppliedDialog
        title={coupon.code}
        open={couponAppliedDialogVisibility}
        onClose={onCouponAppliedDialogClosed}
      />
    </PlanPageContainer>
  );
}

export default ProductListing;
