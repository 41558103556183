export const themeOptions = {
	typography: {
		fontFamily: [
			'Open Sans',
			'Fredoka',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif'
		].join(',')
	},
	palette: {
		mode: 'light',
		background: {
			default: '#FFFFFF',
		},
		primary: {
			main: '#3777B4',
		},
		secondary: {
			main: '#009b5a',
		},
	},
};
