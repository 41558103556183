import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";

export default function TopicSkeleton() {
  return (
    <Stack spacing={1}>
      <Grid container justifyContent="space-between">
        <Grid item >
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={40} width={150} />
        </Grid>
        <Grid item >
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={40} width={150} />
        </Grid>
      </Grid>
      <Skeleton variant="rectangular" width="100%" height={10} sx={{mb:10, mt:10}} />
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton variant="circular" height={30} width={30} />
        </Grid>
        <Grid item  sx={{alignSelf:"center"}}>
          <Skeleton variant="rectangular" height={20} width={200} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton variant="circular" height={30} width={30} />
        </Grid>
        <Grid item  sx={{alignSelf:"center"}}>
          <Skeleton variant="rectangular" height={20} width={200} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton variant="circular" height={30} width={30} />
        </Grid>
        <Grid item  sx={{alignSelf:"center"}}>
          <Skeleton variant="rectangular" height={20} width={200} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton variant="circular" height={30} width={30} />
        </Grid>
        <Grid item  sx={{alignSelf:"center"}}>
          <Skeleton variant="rectangular" height={20} width={200} />
        </Grid>
      </Grid>
    </Stack>
  );
}
