import { isSuitableContentForAge } from "utils/ageGroupFilter";
import { getDocuments, snapshotToArray } from "utils/firebase/firestore";

const { LambdaAPIClient } = require("utils/httpClient");
const { default: sessionHelper } = require("utils/sessionHelper");

async function getUserStats() {
  const userId = sessionHelper.id;
  const childId = sessionHelper.childId;

  const response = await new LambdaAPIClient({
    path: `/saveUserActivity/user/insights/${userId}/${childId}`,
  }).get();

  if (!response.ok) {
    throw new Error(response?.message || "Uh oh! Unable to get user stats");
  }

  return response.insights;
}

async function getSubjects() {
  try {
    const snapshot = await getDocuments({
      appName: "kutuki-secondary",
      collectionName: "insight_data",
      conditions: [
        { field: "type", operator: "eq", value: "subject" },
        { field: "is_active", operator: "eq", value: true },
      ],
    });

    const result = snapshotToArray(snapshot);
    return result.sort((a,b) => a.displayPosition - b.displayPosition);
  } catch (error) {}
}

async function getTopics(subjectId, fetchVideosOnlyTopic) {

  let conditions = [{ field: "parentId", operator: "eq", value: subjectId }];

  if (fetchVideosOnlyTopic) {
    conditions.push({ field: "hasVideos", operator: "eq", value: true })
  }

  try {
    const snapshot = await getDocuments({
      appName: "kutuki-secondary",
      collectionName: `insight_data/${subjectId}/topics`,
      conditions: conditions,
    });

    const result = snapshotToArray(snapshot);
    const topics = result.sort((a,b) => a.displayPosition - b.displayPosition);

    const learningLevel = sessionHelper.learningLevel;

    return topics.filter((topic) => topic.contentCount[learningLevel]);
  } catch (error) {}
}

async function getActivities(subjectId, topicId) {
  try {
    const snapshot = await getDocuments({
      appName: "kutuki-secondary",
      collectionName: `insight_data/${subjectId}/topics/${topicId}/activities`,
      conditions: [{ field: "isActive", operator: "eq", value: true }],
    });

    const result = snapshotToArray(snapshot);
    const activities = result.sort(
      (a, b) => a.displayPosition - b.displayPosition
    );

    return activities.filter((activity) =>
      isSuitableContentForAge(activity.age, sessionHelper.learningLevel)
    );
  } catch (error) {}
}

async function getContentMappedToTopic(subjectId, topicId) {
  try {
    const snapshot = await getDocuments({
      appName: "kutuki-secondary",
      collectionName: `insight_data/${subjectId}/topics/${topicId}/activities`,
      conditions: [
        { field: "isActive", operator: "eq", value: true },
        { field: "activityType", operator: "eq", value: "content" },
      ],
    });

    const result = snapshotToArray(snapshot);
    const contents = result.sort(
      (a, b) => a.displayPosition - b.displayPosition
    );

    return contents.filter((content) =>
      isSuitableContentForAge(content.age, sessionHelper.learningLevel)
    );
  } catch (error) {}
}

async function saveUserActivity(data) {

  const userId = sessionHelper.id;
  const childId = sessionHelper.childId;

  const response = await new LambdaAPIClient({
    path: `/saveUserActivity/user/activity/${userId}/${childId}`,
    payload: data
  }).post();

  if (!response.ok) {
    throw new Error(response?.message || "Uh oh! Unable to get user stats");
  }
  
  sessionHelper.incrementStarsEarned(data.starsEarned);

  return response.insights;
}

export { getUserStats, getSubjects, getTopics, getActivities, saveUserActivity,getContentMappedToTopic };
