import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import DownloadLinks from "features/payments/components/DownloadLinks";
import Footer from "features/payments/components/Footer";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { SecondaryTypographyCaption } from "components/typography/SecondaryTypography";
import { PrimaryTypographyCaption } from "components/typography/PrimaryTypography";

import {
  getActivities,
  getSubjects,
  getTopics,
  getUserStats,
} from "./services/insightsService";
import InsightsSkeleton from "./components/InsightsSkeleton";
import SubjectAccordion from "./components/SubjectAccordion";
import DonutChart from "./components/DonutChart";
import { useTheme } from "@emotion/react";
import { trackEvent } from "utils/analytics";

const InsightPageContainer = styled("div")`
  padding: 64px 0px 20px;
  height: 100%;
  position: relative;
  background-color: #006086;
`;

export default function Insights() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [statType, setStatType] = useState("weekly");
  const [selected, setSelected] = useState(0);
  const [userStats, setUserStats] = useState(undefined);
  const [subjects, setSubjects] = useState(undefined);
  const [computedStats, setComputedStats] = useState(undefined);
  const [accordionStates, setAccordionStates] = useState(undefined);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    const allSubjects = await getSubjects();
    const userInsights = await getUserStats();
    const computedData = getComputedDonutChartData(userInsights, allSubjects);

    setAccordionStates(
      allSubjects.reduce((acc, sub) => {
        acc[sub.code] = false;
        return acc;
      }, {})
    );

    setComputedStats(computedData);
    setSubjects(allSubjects);
    setUserStats(userInsights);
  };

  const getComputedDonutChartData = (userStats, subjects, type) => {
    const statTypeValue = type || statType;
    const computedData = Object.keys(
      userStats[statTypeValue].subjectStats
    ).reduce(
      (acc, subjectCode) => {
        const subject = subjects.find((s) => s.code === subjectCode);
        const subjectStat = userStats[statTypeValue].subjectStats[subjectCode];
        acc["totalScreenTime"] += subjectStat.screenTime;
        acc["subjectStats"][subjectCode] = { ...subjectStat, ...subject };
        return acc;
      },
      { totalScreenTime: 0, subjectStats: {} }
    );

    return computedData;
  };

  const formattedTime = (totalSeconds) => {
    var totalYears = Math.floor(totalSeconds / (3600 * 24 * 365));
    var totalDays = Math.floor(
      (totalSeconds % (3600 * 24 * 365)) / (3600 * 24)
    );
    var totalHours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    var totalMinutes = Math.floor((totalSeconds % 3600) / 60);
    totalSeconds = Math.floor(totalSeconds % 60);
    var timeString = "";
    if (totalYears > 0) {
      timeString = totalYears + "y ";
    }
    if (totalDays > 0) {
      timeString = timeString + totalDays + "d ";
    }
    if (totalHours > 0 && totalYears <= 0) {
      timeString = timeString + totalHours + "h ";
    }
    if (totalMinutes > 0 && totalDays <= 0) {
      timeString = timeString + totalMinutes + "m ";
    }
    if (totalSeconds > 0 && totalHours <= 0) {
      timeString = timeString + totalSeconds + "s";
    }
    return timeString === "" ? "0 min" : timeString;
  };

  const onStatTypeChange = (type) => {
    if (statType === type) return;
    setAccordionStates(
      Object.keys(accordionStates).reduce((acc, code) => {
        acc[code] = false;
        return acc;
      }, {})
    );
    setStatType(type);
    const computedData = getComputedDonutChartData(userStats, subjects, type);
    setComputedStats(computedData);

    trackEvent("INSIGHTS_STAT_TYPE_CHANGED", {
      sourceId: "subscription_site",
      type: type,
      sourceName: "insightsScreen"
    });

  };

  const setSelectedArc = (index) => {
    setSelected(index);
  };

  const getTopicsMappedToSubject = async (subjectId, subjectCode) => {
    
    trackEvent("INSIGHTS_SUBJECT_STATS_VIEWED", {
      sourceId: "subscription_site",
      subjectId: subjectId,
      subjectCode: subjectCode,
      sourceName: "insightsScreen"
    });

    if (computedStats.subjectStats[subjectCode].topics) {
      return;
    }
    let topics = await getTopics(subjectId);
    const activitiesCompletedIds = userStats[statType].activitiesCompleted.map(
      (completedActivity) => completedActivity.entityId
    );
    for (let count = 0; count < topics.length; count++) {
      const topic = topics[count];
      let activities = await getActivities(subjectId, topic.id);
      let activitiesCompletedCount = 0;
      activities.forEach((activity) => {
        if (activitiesCompletedIds.includes(activity.id)) {
          activity.isCompleted = true;
          activitiesCompletedCount += 1;
        } else {
          activity.isCompleted = false;
        }
      });
      topic["activities"] = activities;
      topic["activitiesCompletedCount"] = activitiesCompletedCount;
    }

    const updatedStats = { ...computedStats };
    updatedStats.subjectStats[subjectCode]["topics"] = topics;
    setComputedStats(updatedStats);
  };

  return (
    <InsightPageContainer>
      {userStats && subjects ? (
        <Box
          sx={{
            backgroundColor: "#F7FFFE",
            borderTopLeftRadius: !isMobile ? "32px" : "0px",
            borderBottomLeftRadius: !isMobile ? "32px" : "0px",
            mb: 10,
            padding: 3,
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <Grid item xs={12} md={5} sm={12}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={12} md={12} sm={12}>
                  <SecondaryTypographyCaption
                    sx={{
                      fontSize: 24,
                      fontWeight: "bold",
                    }}
                  >
                    👋 Hello Parent
                  </SecondaryTypographyCaption>
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <FormControl
                    sx={{
                      backgroundColor: "white",
                      display: "block",
                      borderRadius: 4,
                      textAlign: "left",
                      padding: 1,
                      pl: 3,
                      boxShadow: 1,
                    }}
                  >
                    <Select
                      variant="standard"
                      sx={{
                        width: "100%",
                        backgroundColor: "none",
                      }}
                      value={statType}
                      onChange={(event) => onStatTypeChange(event.target.value)}
                      renderValue={(selected) => {
                        // here you can add you logic for your selected value
                        return selected === "weekly"
                          ? "Time Frame: Last 7 days"
                          : "Time Frame: Lifetime"; // something similar to this
                      }}
                      disableUnderline
                    >
                      <MenuItem value="weekly">Last 7 days</MenuItem>
                      <MenuItem value="lifetime">Lifetime</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "16px",
                      boxShadow: 1,
                    }}
                  >
                    <Grid container spacing={0} p={1}>
                      <Grid item xs={6} borderRight={1} borderColor={"#E6E6E6"}>
                        <Grid container alignContent="center">
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            textAlign={"center"}
                          >
                            <PrimaryTypographyCaption
                              sx={{
                                fontSize: 22,
                                fontWeight: 700,
                                color: "#006086",
                              }}
                            >
                              {userStats[statType]?.uniqueActivitiesCompleted ||
                                0}
                            </PrimaryTypographyCaption>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            textAlign={"center"}
                          >
                            <PrimaryTypographyCaption
                              sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#646273",
                              }}
                            >
                              Activities Completed
                            </PrimaryTypographyCaption>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignContent="center">
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            textAlign={"center"}
                          >
                            <PrimaryTypographyCaption
                              sx={{
                                fontSize: 22,
                                fontWeight: 700,
                                color: "#006086",
                              }}
                            >
                              {userStats[statType]?.uniqueLessonsCompleted || 0}
                            </PrimaryTypographyCaption>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            textAlign={"center"}
                          >
                            <PrimaryTypographyCaption
                              sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#646273",
                              }}
                            >
                              Lessons Learnt
                            </PrimaryTypographyCaption>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "16px",
                      boxShadow: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      aspectRatio: "16/10", // Set the aspect ratio here
                      position: "relative", // Ensure the position is relative for absolute positioning of the inner component
                      p: 0,
                    }}
                  >
                    <DonutChart
                      computedStats={computedStats}
                      selected={selected}
                      setSelectedArc={setSelectedArc}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Grid container alignContent="center">
                        <Grid item xs={12} md={12} sm={12} textAlign={"center"}>
                          <PrimaryTypographyCaption
                            sx={{ fontSize: 14, fontWeight: 600 }}
                          >
                            {formattedTime(computedStats.totalScreenTime)}
                          </PrimaryTypographyCaption>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} textAlign={"center"}>
                          <PrimaryTypographyCaption
                            sx={{
                              fontSize: 10,
                              fontWeight: 400,
                              color: "#A3A3A3",
                            }}
                          >
                            of learning
                          </PrimaryTypographyCaption>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sm={12}
              alignSelf={"center"}
              mt={isMobile ? 2 : 6}
              style={
                !isMobile
                  ? {
                      maxHeight: "480px",
                      overflow: "auto",
                    }
                  : {}
              }
            >
              <Grid container rowSpacing={1}>
                {subjects
                  .sort((a, b) => a.displayPosition - b.displayPosition)
                  .map((subject) => (
                    <Grid item xs={12} md={12} sm={12} key={subject.id}>
                      <SubjectAccordion
                        accordionStates={accordionStates}
                        getTopicsMappedToSubject={getTopicsMappedToSubject}
                        setAccordionStates={setAccordionStates}
                        subject={subject}
                        computedStats={computedStats}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#F7FFFE",
            borderTopLeftRadius: "32px",
            borderBottomLeftRadius: "32px",
            mb: 20,
            padding: 5,
          }}
        >
          {" "}
          <InsightsSkeleton />
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
        }}
      >
        <DownloadLinks />
      </Box>
      <Footer />
    </InsightPageContainer>
  );
}
