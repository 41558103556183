class MoEngageAnalytics {

	#instance = null;
	
	constructor() {
		const interval = setInterval(() => {
			if (window.Moengage) {
				clearInterval(interval);
				this.#instance = window.Moengage;
			}
		}, 1000);
	}
	
	// pass dates using new Date()
	async trackEvent(name, payload) {
		try {
			return this.#instance.track_event(name, payload);
		} catch (e) {
			console.error(`MoEngageAnalytics.trackEvent -> error triggering the ${name} event with payload ${JSON.stringify(payload)}`);
			console.error(`MoEngageAnalytics.trackEvent -> error is ${e.message} ${e.stack}`);
		}
		
	}
	
	async trackPhoneNumber(phone) {
		try {
			return this.#instance.add_mobile(phone);
		} catch (e) {
			console.error(`MoEngageAnalytics.trackPhoneNumber -> error setting the mobile user property for ${phone}`);
			console.error(`MoEngageAnalytics.trackPhoneNumber -> error is ${e.message} ${e.stack}`);
		}
	}
	
	async trackEmail(email) {
		try {
			return this.#instance.add_email(email);
		} catch (e) {
			console.error(`MoEngageAnalytics.trackEmail -> error setting the email user property for ${email}`);
			console.error(`MoEngageAnalytics.trackEmail -> error is ${e.message} ${e.stack}`);
		}
	}
	
	async trackUserProps(props) {
		const trackUserPropPromises = [];
		
		const asyncFunc = async (key, value) => {
			try {
				return this.#instance.add_user_attribute(key, value);
			} catch (e) {
				console.error(`MoEngageAnalytics.trackUserProps -> error setting the user prop ${key} ${value}`);
				console.error(`MoEngageAnalytics.trackUserProps -> error is ${e.message} ${e.stack}`);
			}
		}
		
		Object.keys(props)
			.forEach(key => trackUserPropPromises.push(
				asyncFunc(key, props[key])
			));
		
		await Promise.all(trackUserPropPromises);
	}
	
	async trackUserID(userId, isNew = false) {
		try {
			await (isNew
				? this.#instance.add_unique_user_id(userId)
				: this.#instance.update_unique_user_id(userId)
			);
		} catch (e) {
			console.error(`MoEngageAnalytics.trackUserID -> error setting the user ID ${userId}`);
			console.error(`MoEngageAnalytics.trackUserID -> error is ${e.message} ${e.stack}`);
		}
		
	}

	async logout() {
		try {
			await this.#instance.destroy_session();
		} catch (e) {
			console.error(`MoEngageAnalytics.logout -> error logging out user`);
			console.error(`MoEngageAnalytics.logout -> error is ${e.message} ${e.stack}`);
		}
	}
}

const moEngageAnalytics = new MoEngageAnalytics();

export default moEngageAnalytics;
