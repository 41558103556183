import React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import Accordion from "components/accordian/Accordian";
import AccordionDetails from "components/accordian/AccordionDetails";
import AccordionSummary from "components/accordian/AccordionSummary";
import {SecondaryTypographyText} from "components/typography/SecondaryTypography";
import styled from "@emotion/styled";

const Product = styled(Accordion)`
  & .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    border: 3px solid #006086;
  }
  box-shadow: none;
`

const ProductSummary = styled(AccordionSummary)`
  background-color: ${(props) => props.backgroundcolor ?  props.backgroundcolor: "#006086"};
  border-radius: 10px;
`

const ProductDurationContainer = styled("div")`
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 4px;
`;

const ProductDurationText = styled(SecondaryTypographyText)`
  margin-bottom: 0;
  font-weight: bold;
`;

const ProductPricingContainer = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const MinPrice = styled(SecondaryTypographyText)`
  color: white;
  font-weight: bold;
`;

const MaxPrice = styled(SecondaryTypographyText)`
  color: white;
  font-weight: bold;
  text-decoration: line-through;
`;

const ProductDescription = styled(AccordionDetails)`
  background-color: white;
  display: flex;
  align-items: center;
  height: 180px;
`;

const ProductCardContainer = styled("div")`
  border: 3px solid #006086;
  border-radius: 15px;
  overflow: hidden;
`;

const ExpandIcon = (props) => (
  <Radio
    checked={props.checked}
    onClick={props.onClick}
    onChange={props.onChange}
    sx = {
      props.sx
    }
  />
);

function formatDurationUnit(unit, value) {
  if (unit === "years") {
    return value > 1 ? unit : "year";
  } else if (unit === "months") {
    return value > 1 ? unit : "month";
  } else if (unit === "days") {
    return value > 1 ? unit : "day";
  } else {
    return "";
  }
}

const ProductCard = (props) => {
  const {
    isExpanded,
    showMaxPrice,
    durationUnit,
    durationValue,
    maxPrice,
    minPrice,
    description,
    onProductSelected,
    isSelected,
    discountedPrice,
    disabled
  } = props;
  
  return (
    <ProductCardContainer>
      <Product disableGutters expanded={isExpanded}>
        <ProductSummary
          onClick={onProductSelected}
          backgroundcolor={disabled ? "grey": undefined}
          expandIcon={
            <ExpandIcon
              checked={isSelected}
              sx={{
                color:"white",
                '&.Mui-checked': {
                  color: '#F24954',
                },
              }}
              
            />
          }
        >
          <ProductDurationContainer>
            <ProductDurationText>
              {durationValue}
            </ProductDurationText>
            <ProductDurationText>
              {formatDurationUnit(durationUnit, durationValue)}
            </ProductDurationText>
          </ProductDurationContainer>
          <ProductPricingContainer>
            <MinPrice gutterBottom={false}>
              &#8377; {discountedPrice ? discountedPrice : minPrice}
            </MinPrice>
            {showMaxPrice
              ? <MaxPrice gutterBottom={false}>&#8377; {maxPrice}</MaxPrice>
              : null
            }
          </ProductPricingContainer>
        </ProductSummary>
        <ProductDescription>
          <div
            style={{ margin: "0 auto", fontSize:"12px" }}
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </ProductDescription>
      </Product>
    </ProductCardContainer>
  );
};

ProductCard.propTypes = {
  isSelected: PropTypes.bool,
  isExpanded: PropTypes.bool,
  showMaxPrice: PropTypes.bool,
  maxPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  minPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  durationUnit: PropTypes.string,
  durationValue: PropTypes.number,
  description: PropTypes.string,
  onProductSelected: PropTypes.func,
  discountedPrice: PropTypes.number,
  disabled: PropTypes.bool
};

ProductCard.defaultProps = {
  isSelected: false,
  isExpanded: false,
  showMaxPrice: true,
  maxPrice: 0,
  minPrice: 0,
  durationUnit: "days",
  durationValue: 0,
  description: "",
  discountedPrice: null,
  disabled: false
};

export default ProductCard;
