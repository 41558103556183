import React from "react";
import {useSnackbar as useNotistack} from "notistack";
import TextButton from "components/buttons/TextButton";

function useSnackbar() {
  const {enqueueSnackbar, closeSnackbar} = useNotistack();

  const handleClick = () => <TextButton sx={{color: "white"}} onClick={() => closeSnackbar()}>Dismiss</TextButton>;

  return (message = "", variant = "error") => enqueueSnackbar(
    message,
    {
      variant,
      action: handleClick
    }
  )
}

export default useSnackbar;
