import React, { useEffect } from "react";
import starsBg from "assets/gif/stars_award_single.gif";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { SecondaryTypographyCaption } from "components/typography/SecondaryTypography";
import { PrimaryTypographyCaption } from "components/typography/PrimaryTypography";
import sessionHelper from "utils/sessionHelper";
import PropTypes from "prop-types";
export default function StarsEarnedDialog({ handleHideStarsEarnedDialog, ...props }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const timer = setTimeout(() => {
      handleHideStarsEarnedDialog(true);
      // Close the dialog after 4 seconds
    }, 4000);

    return () => {
      clearTimeout(timer); // Clean up the timer when the component unmounts
    };
  }, [handleHideStarsEarnedDialog]);

  return (
    <Grid
      container
      direction={"row"}
      sx={{
        width: isMobile ? "60vw" : 350,
        height: isMobile ? 230 : 230,
        backgroundColor: "#0B7999",
        borderRadius: 5,
      }}
    >
      <Grid sm={12} md={12} xs={12} item m={0}>
        <SecondaryTypographyCaption
          sx={{
            display: "flex",
            color: "white",
            mt: 2,
            fontSize: 20,
            fontWeight: 900,
            justifyContent: "center",
          }}
        >
          Congratulations
        </SecondaryTypographyCaption>
      </Grid>
      <Grid
        sm={12}
        md={12}
        xs={12}
        item
        m={0}
        style={{
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={starsBg}
          alt="bg"
          style={{
            width: 150,
            height: 150,
          }}
        />
        <PrimaryTypographyCaption
          sx={{
            position: "absolute",
            color: "white",
            fontSize: 12,
            fontWeight: 900,
            bottom: 54,
            justifyContent: "center",
          }}
        >
          Total {sessionHelper?.starsEarned + 1 || 1}
        </PrimaryTypographyCaption>
      </Grid>
    </Grid>
  );
}

StarsEarnedDialog.propTypes = {
    handleHideStarsEarnedDialog: PropTypes.func
}
