import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../../Router";
import styled from "@emotion/styled";
import { Box, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { SecondaryTypographyText } from "components/typography/SecondaryTypography";
import TextInput from "components/inputs/TextInput";
import TextButton from "components/buttons/TextButton";
import { PhoneNumberInput } from "components/inputs/PhoneNumberInput";
import { grey } from "@mui/material/colors";
import { signUp } from "features/authenticate/services/authService";
import { useLoadingOverlay } from "components/LoadingOverlay";
import useSnackBar from "hooks/useSnackbar";
import parsePhoneNumber from 'libphonenumber-js'
import logo from "assets/images/logo.png";

const SignUpPageContainer = styled("div")`
  display: flex;
  width: 100vw;
  height: 100vh;
  padding-top: 20px;
`;

const SignUpOptionsContainer = styled("div")`
  border-radius: 36px;
  border: 4px solid #006086;
  background-color: white;
  margin: auto;
  padding: 12px;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Register = () => {
  const showSnackBar = useSnackBar();
  const location = useLocation();
  const { navigateTo } = useNavigation();
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  function isValidEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const [formValues, setFormValues] = useState({
    phone: {
      value: location.state.phone,
      error: false,
      errorMessage: "You must enter the phone number",
      required: false,
    },
    age: {
      value: 3,
      error: false,
      errorMessage: "You must enter an age",
      required: true,
    },
    emailId: {
      value: location.state.email,
      error: false,
      errorMessage: "You must enter the valid email",
      required: false,
    },
    parentName: {
      value: "",
      error: false,
      errorMessage: "You must enter parent name",
      required: false,
    },
    childName: {
      value: "",
      error: false,
      errorMessage: "You must enter child name",
      required: true,
    },
    gender: {
      value: "other",
      error: false,
      errorMessage: "",
    },
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "age") {
      value = parseInt(e.target.value, 10);
      if (isNaN(value)) value = 0;
      if (value > 12) value = 12;
      if (value < 0) value = 0;
    }

    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
        error: false,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateFormFields();
    if (!isValid) {
      return;
    }

    const isValidPhone = validatePhoneNumber(formValues.phone.value);

    if(!isValidPhone) {
      showSnackBar("Please check your phone number and try again!");
      return;
    }

    showLoadingOverlay();

    try {
     const { isRegistered } = await signUp({
       phone:
         parsePhoneNumber(formValues.phone.value) &&
         parsePhoneNumber(formValues.phone.value)?.isValid()
           ? parsePhoneNumber(formValues.phone.value)?.number
           : undefined,
       parentName: formValues.parentName.value,
       childName: formValues.childName.value,
       emailId: formValues.emailId.value,
       age: formValues.age.value,
       gender: formValues.gender.value,
     });

      if(isRegistered) {
        navigateTo("/");
      }
      hideLoadingOverlay();
    } catch (error) {
      hideLoadingOverlay();
      showSnackBar("Oops! It looks like there was an issue with your signup details.");
    }
  };

  const validateFormFields = () => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues };
    let isValid = true;

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;
      const isRequired = formValues[currentField].required;

      if (
        currentField === "emailId" &&
        currentValue &&
        currentValue.length > 0 &&
        !isValidEmail(currentValue)
      ) {
        isValid = false;
        newFormValues = {
          ...newFormValues,
          emailId: {
            ...newFormValues["emailId"],
            error: true,
          },
        };
        continue;
      }

      if ((currentValue === "" || currentValue === null) && isRequired) {
        isValid = false;
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          },
        };
      }
    }

    setFormValues(newFormValues);
    return isValid;
  };

  const validatePhoneNumber = (phone) => {
     const phoneNumber = parsePhoneNumber(phone);
     if(phoneNumber) {
       return phoneNumber.isValid();
     }
     return true;
  }

  const handleGenderChange = (event, value) => {
    event.preventDefault();
    setFormValues({
      ...formValues,
      gender: {
        ...formValues["gender"],
        value,
      },
    });
  };

  const handlePhoneChange = (phone) => {
    setFormValues({
      ...formValues,
      phone: {
        ...formValues["phone"],
        phone,
      },
    });
  };

  return (
    <SignUpPageContainer>
      <SignUpOptionsContainer>
      <Grid item xs={12} md={12} display="flex" sx={{alignItems:"center"}}>
        <div style={{ margin: "0 auto" }}>
          <img
            style={{
              width: "100px",
            }}
            src={logo}
            alt="Kutuki"
          />
        </div>
      </Grid>
        <Box flexDirection="column" sx={{ p: 1 }} display={"flex"}>
          <SecondaryTypographyText
            sx={{ color: "#333333", fontSize: "16px", p: 0, mt: 2 }}
          >
            Personal Details
          </SecondaryTypographyText>

          <Grid container spacing={4}>
            <Grid item md={6} sm={6} xs={12}>
              <TextInput
                placeholder={`Parent's Name`}
                label={`Parent's Name`}
                sx={{ width: "100%" }}
                name="parentName"
                onChange={handleChange}
                value={formValues.parentName.value}
                error={formValues.parentName.error}
                helperText={
                  formValues.parentName.error
                    ? formValues.parentName.errorMessage
                    : ""
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <PhoneNumberInput
                sx={{
                  "--react-international-phone-border-radius": "8px",
                  "--react-international-phone-border-color": grey[300],
                  "--react-international-phone-height": "56px",
                }}
                name="phone"
                onChange={handlePhoneChange}
                phone={
                  location?.state?.phone && location?.state?.phone.length > 0
                    ? location.state.phone
                    : formValues.phone.value
                }
                disabled={
                  location?.state?.phone && location?.state?.phone.length > 9
                }
              />
            </Grid>
          </Grid>
          <TextInput
            placeholder={
              location?.state?.emailId && location?.state?.emailId.length > 0
                ? ""
                : `Email Address`
            }
            label={`Email`}
            sx={{ mt: 4, width: "100%" }}
            name="emailId"
            type="email"
            onChange={handleChange}
            disabled={
              location?.state?.emailId && location?.state?.emailId.length > 0
            }
            value={
              location?.state?.emailId && location?.state?.emailId.length
                ? location.state.emailId
                : formValues.emailId.value
            }
            error={formValues.emailId.error}
            helperText={
              formValues.emailId.error ? formValues.emailId.errorMessage : ""
            }
          />

          <SecondaryTypographyText
            sx={{ color: "#333333", fontSize: "16px", p: 0, mt: 2 }}
          >
            Child Details
          </SecondaryTypographyText>

          <Grid container spacing={4}>
            <Grid item md={6} sm={6} xs={12}>
              <TextInput
                placeholder={`Child's Name`}
                label={`Child's Name`}
                sx={{ width: "100%" }}
                name="childName"
                onChange={handleChange}
                value={formValues.childName.value}
                error={formValues.childName.error}
                helperText={
                  formValues.childName.error
                    ? formValues.childName.errorMessage
                    : ""
                }
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <TextInput
                placeholder={`Age`}
                label={`Age`}
                sx={{ width: "100%" }}
                type="number"
                inputProps={{
                  max: 12,
                  min: 3,
                  maxLength: 2,
                }}
                name="age"
                onChange={handleChange}
                value={formValues.age.value}
                error={formValues.age.error}
                helperText={
                  formValues.age.error ? formValues.age.errorMessage : ""
                }
              />
            </Grid>
          </Grid>
          <SecondaryTypographyText sx={{ fontSize: "12px", p: 0, mt: 2 }}>
            Gender
          </SecondaryTypographyText>
          <ToggleButtonGroup
            color="secondary"
            value={formValues["gender"].value}
            exclusive
            name="gender"
            onChange={handleGenderChange}
            aria-label="Platform"
            sx={{ width: "100%", fontFamily: "Fredoka" }}
          >
            <ToggleButton
              sx={{ width: "100%", fontFamily: "Fredoka" }}
              value="girl"
            >
              Girl
            </ToggleButton>
            <ToggleButton
              sx={{ width: "100%", fontFamily: "Fredoka" }}
              value="boy"
            >
              Boy
            </ToggleButton>
            <ToggleButton
              sx={{ width: "100%", fontFamily: "Fredoka" }}
              value="other"
            >
              Other
            </ToggleButton>
          </ToggleButtonGroup>

          <TextButton
            sx={{
              fontFamily: "Fredoka",
              fontSize: "16px",
              fontWeight: 900,
              color: "white",
              backgroundColor: "#F24954",
              width: "150px",
              borderRadius: "10px",
              m: "0 auto",
              mt: 4,
              "&:hover": {
                backgroundColor: "#F24954",
              },
            }}
            onClick={handleSubmit}
          >
            Proceed
          </TextButton>
        </Box>
      </SignUpOptionsContainer>
    </SignUpPageContainer>
  );
};

export default Register;
