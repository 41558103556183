import { Suspense } from "react";
import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { themeOptions } from "configuration/theme";
import { SnackbarProvider } from "notistack";
import { LoadingOverlayProvider } from "components/LoadingOverlay";
import RouterComponent from "Router";
import CacheBuster from 'react-cache-buster';
import configuration from "configuration";
import LoadingScreen from "components/LoadingScreen";

const theme = createTheme(themeOptions);

function App() {
  return (
    <CacheBuster
      currentVersion={configuration.app_version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<LoadingScreen/>}
      metaFileDirectory={'.'}
    >
    <ThemeProvider theme={theme}>
      <LoadingOverlayProvider>
      <SnackbarProvider maxSnack={7} autoHideDuration={3000}>
      <CssBaseline />
      <Suspense fallback={<LoadingScreen/>}>
        <RouterComponent />
      </Suspense>
      </SnackbarProvider>
      </LoadingOverlayProvider>
    </ThemeProvider>
    </CacheBuster>
  );
}

export default App;
