import PropTypes from "prop-types";
import TextButton from "components/buttons/TextButton";
import ContainedButton from "components/buttons/ContainedButton";
import {
  PrimaryTypographyHeading4,
  PrimaryTypographySubtitle,
} from "components/typography/PrimaryTypography";
import { SecondaryTypographyText } from "components/typography/SecondaryTypography";

import { styled } from "@mui/material/styles";
import TextInput from "components/inputs/TextInput";
import { Box, Grid } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const PromoCodeContainer = styled("div")`
  background-color: #fef0ca;
  width: 100%;
  padding: 20px 0;
  text-align: center;
`;

const PromoCodeInputContainer = styled("form")`
  background-color: #fff;
  border-radius: 5px;
  max-width: 380px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PromoCodeInput = styled(TextInput)`
  flex: 1;
  background-color: #fff;
  border: none;
  box-shadow: none;
  & .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  :hover {
    border-color: transparent;
  }
`;

const PayOrRedeemButton = styled(ContainedButton)`
  width: 200px;
  font-weight: bold;
`;

function PromoCode(props) {
  const {
    coupon,
    couponInputDisabled,
    isFullyRedeemable,
    onCouponChanged,
    onApplyButtonPressed,
    onRemoveButtonPressed,
    onRedeemButtonPressed,
    onPayButtonPressed,
    price,
    discountedPrice,
  } = props;

  return (
    <PromoCodeContainer>
      <PrimaryTypographyHeading4 align={"center"} sx={{fontWeight: "800"}}>
        Promo Code
      </PrimaryTypographyHeading4>
      <PrimaryTypographySubtitle align={"center"} sx={{fontWeight: "500"}}>
        Complete the following details to proceed further
      </PrimaryTypographySubtitle>
      <PromoCodeInputContainer onSubmit={onApplyButtonPressed}>
        {couponInputDisabled ? (
          <Box
            flexDirection="row"
            width="100%"
            height="60px"
            ml={1}
            display="flex"
            justifyContent="flex-start"
          >
            <CheckCircle
              sx={{
                fill: "green",
                fontSize: 56,
                p: 0,
                mr: 1,
                alignSelf: "center",
              }}
            />
            <Grid flexDirection="column" sx={{ alignSelf: "center" }} m={0}>
              <Grid item ml={1}>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <SecondaryTypographyText
                    sx={{ fontSize: "18", m: 0, p: 0, fontWeight: "900" }}
                  >
                    {coupon}
                  </SecondaryTypographyText>
                  <SecondaryTypographyText
                    sx={{ fontSize: "16px", color: "grey", ml: 1, p: 0 }}
                  >
                    applied
                  </SecondaryTypographyText>
                </Box>
              </Grid>
              <Grid item ml={1} alignItems="center">
                <Box flexDirection="row" display="flex">
                  <SecondaryTypographyText
                    sx={{
                      fontSize: "12px",
                      color: "grey",
                      textDecoration: "line-through",
                      m: 0,
                      p: 0,
                    }}
                  >
                    &#8377; {price}
                  </SecondaryTypographyText>
                  {discountedPrice !== 0 ? (
                    <SecondaryTypographyText
                      sx={{
                        fontSize: "12px",
                        color: "black",
                        fontWeight: "800",
                        ml: 1,
                        p: 0,
                      }}
                    >
                      &#8377; {discountedPrice}
                    </SecondaryTypographyText>
                  ) : (
                    <SecondaryTypographyText
                      sx={{
                        fontSize: "12px",
                        color: "black",
                        fontWeight: "800",
                        ml: 1,
                      }}
                    >
                      FREE
                    </SecondaryTypographyText>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : null}
        {!couponInputDisabled ? (
          <PromoCodeInput
            value={coupon}
            disabled={couponInputDisabled}
            placeholder="Enter your promo code"
            onChange={onCouponChanged}
            sx={{borderRadius: "5px"}}
          />
        ) : null}
        {couponInputDisabled ? (
          <TextButton
            color="error"
            onClick={onRemoveButtonPressed}
            sx={{ width: 100 , fontWeight: 700}}
          >
            Remove
          </TextButton>
        ) : (
          <TextButton
            // onClick={onApplyButtonPressed}
            sx={{ width: 100, fontWeight: 700 }}
            type="submit"
          >
            Apply
          </TextButton>
        )}
      </PromoCodeInputContainer>
      {couponInputDisabled && coupon ? (
        <SecondaryTypographyText
          sx={{
            fontSize: "14px",
            color: "green",
            textAlign: "center",
            m: "0 auto",
            pb: 2,
            maxWidth: "380px",
            alignSelf: "center",
          }}
        >
          Promo Code Added
        </SecondaryTypographyText>
      ) : null}
      {isFullyRedeemable ? (
        <PayOrRedeemButton
          fullWidth={false}
          color={"secondary"}
          onClick={onRedeemButtonPressed}
        >
          Redeem
        </PayOrRedeemButton>
      ) : (
        <PayOrRedeemButton
          fullWidth={false}
          color={"primary"}
          onClick={onPayButtonPressed}
        >
          Buy Now
        </PayOrRedeemButton>
      )}
    </PromoCodeContainer>
  );
}

PromoCode.propTypes = {
  coupon: PropTypes.string,
  couponInputDisabled: PropTypes.bool,
  isFullyRedeemable: PropTypes.bool,
  onCouponChanged: PropTypes.func.isRequired,
  onApplyButtonPressed: PropTypes.func.isRequired,
  onRemoveButtonPressed: PropTypes.func.isRequired,
  onRedeemButtonPressed: PropTypes.func.isRequired,
  onPayButtonPressed: PropTypes.func.isRequired,
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
};

export default PromoCode;
