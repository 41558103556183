
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

function isSuitableContentForAge(ageGroup, childAgeGroup) {
    if (!ageGroup) {
      return true;
    }
  
    if (ageGroup.length === 0) {
      return true;
    }
  
    if (ageGroup.length === 3) {
      return true;
    }
  
    var contentAgeGroup = ageGroup;
  
    if (contentAgeGroup.length === 1) {
      contentAgeGroup.push(12);
    }
  
    const ageGroupMap = {
      nursery: [0, 3.99],
      lkg: [4, 4.99],
      ukg: [5, 5.99],
      grade1: [6, 6.99],
      grade2: [7, 7.99]
    };
  
    const suitableAgeGroups = [];
  
    Object.keys(ageGroupMap).forEach((grp) => {
      let ageGroupValues = ageGroupMap[grp];
  
      let isSuitable = false;
  
      isSuitable =
        clamp(
          contentAgeGroup[0],
          ageGroupValues[0] + 0.1,
          ageGroupValues[0] - 0.1
        ) === contentAgeGroup[0] ||
        clamp(contentAgeGroup[1], ageGroupValues[0] + 0.1, ageGroupValues[1]) ===
        contentAgeGroup[1] ||
        (contentAgeGroup[0] > ageGroupValues[0] &&
          contentAgeGroup[0] <= ageGroupValues[1] - 0.1) ||
        (contentAgeGroup[1] > ageGroupValues[0] &&
          contentAgeGroup[1] <= ageGroupValues[1]) ||
        (contentAgeGroup[0] <= ageGroupValues[0] &&
          contentAgeGroup[1] >= ageGroupValues[1]);
  
      if (isSuitable) {
        suitableAgeGroups.push(grp);
      }
    });
  
    return suitableAgeGroups.includes(childAgeGroup);
  }

  export {
    isSuitableContentForAge
  }