import { setUserProperties, trackEvent, trackGtagEvent } from "utils/analytics";
import { getDocument } from "utils/firebase/firestore";
import { APIClient } from "utils/httpClient";
import rollbarService from "utils/rollbarService";
import sessionHelper from "utils/sessionHelper";
import config from "configuration";

async function _trackCouponRedeemed(data) {
	trackEvent("CouponRedeemed", {
		sourceId: "subscription_site",
		code: data.code, // code
		productId: data.productId, // plan id
		productName: data?.productName || "",
		sourceName: "subscriptionScreen",
		email: data.email || config.default_email,
		phone: data.phone,
	}).catch((e) => {
		rollbarService?.logError(
			`_trackCouponRedeemed -> error occurred tracking the coupon redeem`,
			{
				code: e.code || "",
				message: e.message,
			});

      trackGtagEvent({type:"event", event:"exception", payload: {
        fatal: false,
        description: e.message,
      }});
	});

  trackGtagEvent({type:"event", event: "select_promotion", payload:{
		creative_name: data.code,
		promotion_id: data?.code || ""
	}});
	
	setUserProperties({
		SubscriptionExpiryDateV2: data.expiry,
	}).catch((e) => {
		rollbarService?.logError(
			`setUserProperties -> error occurred while setting user props`,
			{
				code: e.code || "",
				message: e.message,
			});

      trackGtagEvent({type:"event", event:"exception", payload: {
        fatal: false,
        description: e.message,
      }});
	});
}

async function _trackCouponApplied(data) {
	trackEvent("CouponApplied", {
		sourceId: "subscription_site",
		code: data.code, // code
		productId: data.productId, // plan id
		discountType: data.discountType,
		discountValue: data.discountValue,
		sourceName: "subscriptionScreen",
		phone: data.phone,
		email: data.email || config.default_email,
	}).catch((e) => {
		rollbarService?.logError(
			`_trackCouponApplied -> error occurred tracking the coupon apply`,
			{
				code: e.code || "",
				message: e.message,
			});

      trackGtagEvent({type:"event", event:"exception", payload: {
        fatal: false,
        description: e.message,
      }});
	});
}

async function validateCouponService(coupon) {
  const apiClient = new APIClient({
    path: "/coupon/validate/" + coupon,
  });
	
  const result = await apiClient.get();

  if (result.code !== 200) {
    console.error(`validateCouponService -> error occurred validating the coupon ${coupon}`);
		
    rollbarService?.logError(
      `validateCouponService -> error occurred validating the coupon ${coupon}`,
      {
        code: result.code || "",
        message: result.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: false,
      description: result.message,
    }});
		
    throw new Error(result.message);
  }

  _trackCouponApplied({
		code: coupon,
		productId: result?.data?.coupon?.productId || "",
		discountType: result?.data?.coupon?.discountType || "",
		discountValue: result?.data?.coupon?.discountValue || 0,
		phone: sessionHelper.phone,
		email: sessionHelper.email,
  });

  return result.data.coupon;
}

async function redeemCouponService(coupon) {
  const apiClient = new APIClient({
    path: "/coupon/redeem",
    payload: {
      code: coupon,
    },
  });

  const result = await apiClient.post();

  if (result.code !== 200) {
    console.error(
      `redeemCouponService -> error occurred while redeeming the coupon ${coupon}`
    );
    rollbarService?.logError(
      `redeemCouponService -> error occurred validating the coupon ${coupon}`,
      {
        code: result.code || "",
        message: result.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: false,
      description: result.message,
    }});

    throw new Error(result.message);
  }

  _trackCouponRedeemed({
		code: coupon,
		productId: result.data?.subscription.product?.id,
		expiry: result.data?.subscription.expiry,
		productName: result.data?.subscription.product?.name,
		phone: sessionHelper.phone,
		email: sessionHelper.email,
  });

  return result.data;
}

async function getTransactionDialogConfig() {
  try {
    const snapshot = await getDocument({
      appName: "kutuki-secondary",
      collectionName: "appConfiguration/subscription/configuration",
      id: "commonV1",
    });

    const transactionDialogsConfig = snapshot.data();
		
    return transactionDialogsConfig.dialogs;
  } catch (error) {
    console.log(error);
  }
}

export {
  validateCouponService,
  redeemCouponService,
  getTransactionDialogConfig,
};
