import Typography from './Typography';

function SecondaryTypography({ sx, children, ...props }) {
	return (
		<Typography
			{...props}
			sx={{
				fontFamily: 'Fredoka',
				...sx
			}}
		>
			{children}
		</Typography>
	);
}

function SecondaryTypographyHeading1({ children, ...props }) {
	return (
		<SecondaryTypography variant={'h1'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyHeading2({ children, ...props }) {
	return (
		<SecondaryTypography variant={'h2'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyHeading3({ children, ...props }) {
	return (
		<SecondaryTypography variant={'h3'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyHeading4({ children, ...props }) {
	return (
		<SecondaryTypography variant={'h4'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyHeading5({ children, ...props }) {
	return (
		<SecondaryTypography variant={'h5'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyHeading6({ children, ...props }) {
	return (
		<SecondaryTypography variant={'h6'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyText({ children, ...props }) {
	return (
		<SecondaryTypography variant={'body1'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographySubtitle({ children, ...props }) {
	return (
		<SecondaryTypography variant={'subtitle1'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyButton({ children, ...props }) {
	return (
		<SecondaryTypography variant={'button'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

function SecondaryTypographyCaption({ children, ...props }) {
	return (
		<SecondaryTypography variant={'caption'} {...props}>
			{children}
		</SecondaryTypography>
	);
}

export {
	SecondaryTypographyHeading1,
	SecondaryTypographyHeading2,
	SecondaryTypographyHeading3,
	SecondaryTypographyHeading4,
	SecondaryTypographyHeading5,
	SecondaryTypographyHeading6,
	SecondaryTypographyText,
	SecondaryTypographySubtitle,
	SecondaryTypographyButton,
	SecondaryTypographyCaption,
}
