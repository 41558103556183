function getSubscriptionPlan(subscription) {
  let plan = "Free Plan";
  if (subscription.razorpaySubscriptionId) {
    if (
      subscription.durationUnit === "days" &&
      subscription.durationValue === 30
    ) {
      plan = "Monthly Plan";
    } else if (subscription.durationUnit === "month") {
      plan = "Monthly Plan";
    } else {
      plan = "Weekly Plan";
    }
  } else if (
    subscription.durationUnit &&
    subscription.durationValue &&
    ((subscription.durationUnit === "years" &&
      subscription.durationValue >= 1) ||
      (subscription.durationUnit === "days" &&
        subscription.durationValue >= 365) ||
      (subscription.durationUnit === "months" &&
        subscription.durationValue >= 12))
  ) {
    plan = "Yearly Plan";
  } else if (subscription.durationUnit === "months") {
    plan = "Monthly Plan";
  } else {
    plan = "Free Plan";
  }

  return plan;
}

function getDurationUnit(val) {
  const unit = val;

  if (val === "years") {
    return "year";
  }

  if (val === "days") {
    return "days";
  }
  return unit;
}

function convertSubscriptionDurationToDays(unit = "days", value = 0) {
  let duration = 0;
  switch (unit.toUpperCase()) {
    case "YEAR":
    case "YEARS":
      duration = Number(value) * 365;
      break;

    case "MONTH":
    case "MONTHS":
      duration = Number(value) * 30;
      break;

    case "DAYS":
      duration = Number(value);
      break;
    default:
      duration = Number(value);
      break;
  }

  return duration;
}

function hasValidSubscription(expiry) {
  const currentDate = new Date();

  const expiryDate = new Date(expiry);

  return expiryDate - currentDate > 0;
}

export {
  getSubscriptionPlan,
  getDurationUnit,
  convertSubscriptionDurationToDays,
  hasValidSubscription,
};
