import MuiTypography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function Typography({ children, ...props }) {
	return (
		<MuiTypography
			align={props.align}
			classes={props.classes}
			className={props.className}
			component={props.component}
			gutterBottom={props.gutterBottom}
			noWrap={props.noWrap}
			paragraph={props.paragraph}
			sx={props.sx}
			variant={props.variant}
		>
			{children}
		</MuiTypography>
	);
}

Typography.propTypes = {
 	align: PropTypes.oneOf([ 'center', 'inherit', 'justify', 'left', 'right ']),
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	component: PropTypes.elementType,
	gutterBottom: PropTypes.bool,
	noWrap: PropTypes.bool,
	paragraph: PropTypes.bool,
	sx: PropTypes.any,
	variant: PropTypes.oneOf([
		'body1',
		'body2',
		'button',
		'caption',
		'h1',
		'h2',
		'h3',
		'h4',
		'h5',
		'h6',
		'inherit',
		'subtitle1',
		'subtitle2',
	]),
};

Typography.defaultProps = {
	align: 'left',
	gutterBottom: true,
	noWrap: false,
	paragraph: false,
	variant: 'body1'
};

export default Typography;
