import MuiButton from '@mui/material/Button';
import PropTypes from 'prop-types';

function TextButton(props) {
	return (
		<MuiButton
			color={props.color}
			classes={props.classes}
			className={props.className}
			disabled={props.disabled}
			endIcon={props.endIcon}
			fullWidth={props.fullWidth}
			size={props.size}
			startIcon={props.startIcon}
			sx={props.sx}
			type={props.type}
			variant='text'
			onClick={props.onClick}
		>
			{props.children}
		</MuiButton>
	);
}

TextButton.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	color: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.oneOf([ 'success', 'error', 'info', 'primary', 'secondary' ]),
	]),
	disabled: PropTypes.bool,
	endIcon: PropTypes.node,
	fullWidth: PropTypes.bool,
	size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
	startIcon: PropTypes.node,
	sx: PropTypes.any,
	type: PropTypes.oneOf([ 'button', 'submit' ]),
	onClick: PropTypes.func
};

TextButton.defaultProps = {
	disabled: false,
	fullWidth: true,
	size: 'medium',
	type: 'button',
};

export default TextButton;
