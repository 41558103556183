import MuiButton from '@mui/material/Button';
import PropTypes from 'prop-types';

function OutlinedButton(props) {
	return (
		<MuiButton
			disabled={props.disabled}
			endIcon={props.endIcon}
			fullWidth={props.fullWidth}
			size={props.size}
			startIcon={props.startIcon}
			variant='outlined'
			sx={props.sx}
			onClick={props.onClick}
		>
			{props.children}
		</MuiButton>
	);
}

OutlinedButton.propTypes = {
	children: PropTypes.node,
	color: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.oneOf([ 'success', 'error', 'info', 'primary', 'secondary' ]),
	]),
	disabled: PropTypes.bool,
	endIcon: PropTypes.node,
	fullWidth: PropTypes.bool,
	size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
	startIcon: PropTypes.node,
	onClick: PropTypes.func
};

OutlinedButton.defaultProps = {
	disabled: false,
	fullWidth: true,
	size: 'medium'
};

export default OutlinedButton;
