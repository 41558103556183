import React from "react";
import PropTypes from "prop-types";
import Accordion from "components/accordian/Accordian";
import AccordionDetails from "components/accordian/AccordionDetails";
import AccordionSummary from "components/accordian/AccordionSummary";
import { SecondaryTypographyText } from "components/typography/SecondaryTypography";
import styled from "@emotion/styled";
import ContainedButton from "components/buttons/ContainedButton";
import { Grid } from "@mui/material";
import { useNavigation } from "../Router";

const Product = styled(Accordion)`
  & .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    border: 3px solid #006086;
  }
  box-shadow: none;
`;

const ProductSummary = styled(AccordionSummary)`
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : "#006086"};
  border-radius: 10px;
`;

const ProductDurationContainer = styled("div")`
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 4px;
`;

const ProductDurationText = styled(SecondaryTypographyText)`
  margin-bottom: 0;
  font-weight: bold;
  font-size:12px;
`;

const ProductPricingContainer = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const MinPrice = styled(SecondaryTypographyText)`
  color: white;
  font-weight: bold;
`;

const MaxPrice = styled(SecondaryTypographyText)`
  color: white;
  font-weight: bold;
  text-decoration: line-through;
`;

const ProductDescription = styled(AccordionDetails)`
  background-color: white;
  display: flex;
  align-items: center;
`;

const ProductCardContainer = styled("div")`
  border: 3px solid #006086;
  border-radius: 15px;
  overflow: hidden;
`;

function formatDurationUnit(unit, value) {
  if (unit === "years") {
    return value > 1 ? unit : "year";
  } else if (unit === "months") {
    return value > 1 ? unit : "month";
  } else if (unit === "days") {
    return value > 1 ? unit : "day";
  } else {
    return "";
  }
}

const PremiumModal = (props) => {
  const { navigateTo } = useNavigation();

  const {
    showMaxPrice,
    durationUnit,
    durationValue,
    maxPrice,
    minPrice,
    discountedPrice,
  } = props;

  return (
    <ProductCardContainer>
      <Product disableGutters expanded={true}>
        <ProductSummary>
          <ProductDurationContainer>
            <ProductDurationText>{durationValue}</ProductDurationText>
            <ProductDurationText>
              {formatDurationUnit(durationUnit, durationValue)}
            </ProductDurationText>
          </ProductDurationContainer>
          <ProductPricingContainer>
            <MinPrice gutterBottom={false}>
              &#8377; {discountedPrice ? discountedPrice : minPrice}
            </MinPrice>
            {showMaxPrice ? (
              <MaxPrice gutterBottom={false}>&#8377; {maxPrice}</MaxPrice>
            ) : null}
          </ProductPricingContainer>
        </ProductSummary>
        <ProductDescription>
          <Grid container direction={"column"}>
            <Grid item>
              <div
                style={{ margin: "0 auto", fontSize: "10px" }}
                dangerouslySetInnerHTML={{
                  __html: '<b>Best Value Plan</b><br><strong><span style ="color:green">✓</span></strong> Full access to 3000+ videos<br><strong><span style ="color:green">✓</span></strong> Get subject wise weekly learning reports<br><strong><span style ="color:green">✓</span></strong> Easy pay with UPI <br><strong><span style ="color:green">✓</span></strong><strong><span style ="color:red"></span></strong>Automatically renews every month, cancel anytime<br>',
                }}
              />
            </Grid>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 0 }}
            >
              <ContainedButton
                onClick={() => navigateTo("/")}
                sx={{ width: "140px", fontSize: 12, fontWeight: 900, fontFamily: "Open Sans" }}
              >
                Buy Now
              </ContainedButton>
            </Grid>
          </Grid>
        </ProductDescription>
      </Product>
    </ProductCardContainer>
  );
};

PremiumModal.propTypes = {
  showMaxPrice: PropTypes.bool,
  maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  durationUnit: PropTypes.string,
  durationValue: PropTypes.number,
  description: PropTypes.string,
  discountedPrice: PropTypes.number,
};

PremiumModal.propTypes = {
  showMaxPrice: PropTypes.bool,
  maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  durationUnit: PropTypes.string,
  durationValue: PropTypes.number,
  description: PropTypes.string,
  discountedPrice: PropTypes.number,
};

PremiumModal.defaultProps = {
  showMaxPrice: false,
  maxPrice: 0,
  minPrice: 0,
  durationUnit: "days",
  durationValue: 0,
  description: "",
  discountedPrice: null,
};

export default PremiumModal;
