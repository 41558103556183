import Cookies from "js-cookie";
import { getSubscriptionPlan } from "utils/subscription";

const kutIsLoggedIn = Symbol("kutIsLoggedIn");

function setIsLoggedInCookie() {
  Cookies.set(kutIsLoggedIn.description, true, { expires: 30 });
}

class SessionHelper {
  id = null;
  email = null;
  phone = null;
  name = null;
  childId = null;

  token = null;
  tokenExpiry = new Date().getTime();
  subscriptionExpiry = null;
  subscriptionPlan = null;
  razorpaySubscriptionId = null;
  razorpaySubscriptionStatus = null;
  subscriptionStartDate = null;
  learningLevel = null;
  starsEarned = 0;

  setTokens({ accessToken, accessExpireAt, refreshToken, refreshExpireAt }) {
    this.token = accessToken;
    this.tokenExpiry = accessExpireAt;
    
    setIsLoggedInCookie();
    
    if(refreshToken) {
      localStorage.setItem("kutRefreshTokenExpiry", refreshExpireAt);
      localStorage.setItem("kutRefreshToken", refreshToken);
    }
  }
  
  setUserInformation({ profile }) {
    this.id = profile.id;
    this.name = profile.name;
    this.phone = profile.phone;
    this.email = profile.emailId;
    this.childId = profile.childId;
    this.learningLevel = profile.learningLevel;
    this.starsEarned = profile.starsEarned;
  }

  setSubscription({ subscription }) {
    this.subscriptionExpiry = subscription.expiry;
    this.subscriptionPlan = getSubscriptionPlan(subscription);
    this.razorpaySubscriptionId = subscription.razorpaySubscriptionId;
    this.razorpaySubscriptionStatus = subscription.razorpaySubscriptionStatus;
    this.subscriptionStartDate = new Date(subscription.createdAt)
  }

  resetSubscription() {
    this.subscriptionExpiry = null;
    this.subscriptionPlan = null;
    this.razorpaySubscriptionId = null;
    this.razorpaySubscriptionStatus = null;
    this.subscriptionStartDate = null;
  }

  onRestoreSession({ token, tokenExpiry  }) {
    this.token = token;
    this.tokenExpiry = tokenExpiry;
  }

  incrementStarsEarned(val) {
    this.starsEarned += val;
  }

  onSignOut() {
    // resetting the user info
    this.id = null;
    this.name = null;
    this.phone = null;
    this.email = null;
    this.childId = null;
    
    this.resetSubscription();

    // remove the logged in cookie
    Cookies.remove(kutIsLoggedIn.description);
    Cookies.remove("kut_token");
  
    localStorage.removeItem("kutProfile");
    localStorage.removeItem("kutRefreshToken");
    localStorage.removeItem("kutRefreshTokenExpiry");
  }

  isLoggedIn() {
    return Cookies.get(kutIsLoggedIn.description);
  }
}

const sessionHelper = new SessionHelper();

export default sessionHelper;
