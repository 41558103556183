import { APIClient } from "utils/httpClient";
import config from "configuration";
import sessionHelper from "utils/sessionHelper";
import { trackEvent, setUserProperties, trackGtagEvent } from "utils/analytics";
import rollbarService from "utils/rollbarService";
import {convertSubscriptionDurationToDays} from "utils/subscription";

function loadScript(documentRef, scriptSource) {
  return new Promise((resolve) => {
    const script = document.createElement("script");

    script.src = scriptSource;

    script.onload = () => {
      resolve(true);
    };

    script.onerror = () => {
      resolve(false);
    };

    documentRef.body.appendChild(script);
  });
}

async function _trackPaymentSuccessful(data) {
  trackEvent("PurchasedSuccessfully", {
    sourceId: "subscription_site",
    amount: data.amount, // number
    product: data.product, // plan title
    duration: data.duration, // number
    paymentMethod: "RAZOR_PAY",
    paymentGateway: "RAZOR_PAY",
    coupon: data.couponCode,
    sourceName: "subscriptionScreen",
    phone: data.phone,
    email: data.email || config.default_email,
  }).catch((e) => {
    rollbarService?.logError(
      `_trackPaymentSuccessful -> error occurred tracking the payment success`,
      {
        code: e.code || "",
        message: e.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: false,
      description: e.message,
    }});

  });

  setUserProperties({
    SubscriptionExpiryDateV2: data.expiry,
  }).catch((e) => {
    rollbarService?.logError(
      `setUserProperties -> error occurred while setting user props`,
      {
        code: e.code || "",
        message: e.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: false,
      description: e.message,
    }});

  });
}

async function _trackPaymentFailed(data) {
  trackEvent("FailedPayment", {
    sourceId: "subscription_site",
    amount: data.amount,
    productName: data.productName, // plan title
    paymentGateway: "RAZOR_PAY",
    paymentMethod: "RAZOR_PAY",
    sourceName: "subscriptionScreen",
    error: data.error,
    currencyCode: "INR",
    phone: data.phone,
    email: data.email || config.default_email,
  }).catch((e) => {
    rollbarService?.logError(
      `_trackPaymentFailed -> error occurred while tracking payment `,
      {
        code: e.code || "",
        message: e.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: false,
      description: e.message,
    }});

  });
}

async function _paymentHandler({
  paymentId,
  orderId,
  signature,
  transactionId,
  postVerifyCb,
  couponCode,
  amount,
  email,
  phone,
}) {
  let subscription;

  try {
    const result = await verifyPayment({
      orderId,
      transactionId,
      paymentId,
      signature
    });

    subscription = result.subscription;

    sessionHelper.setSubscription({ subscription });

    const product = subscription.product;

    await _trackPaymentSuccessful({
      amount: amount || product.listPrice,
      duration: convertSubscriptionDurationToDays(product.durationUnit, product.durationValue),
      product: product.name,
      couponCode: couponCode || "",
      email,
      phone,
    });

    trackGtagEvent({type: "event", event: "purchase",payload: {
      currency: "INR",
      value: amount || product.listPrice,
      transaction_id: transactionId,
      coupon: couponCode || "",
      items: [{
        item_id: product.id,
        item_name: product.name
      }]
    }});

    postVerifyCb({
      error: false,
      data: result,
    });
  } catch (e) {
    console.error(`paymentHandler -> ${e.message} ${e.stack}`);

    const product = subscription.product;

    await _trackPaymentFailed({
      amount: amount || product.listPrice,
      duration: product.durationValue,
      productName: product.name,
      error: e.message,
      email,
      phone,
    });
    
    postVerifyCb({
      error: true,
      message: e.message,
    });

    rollbarService?.logError(
      `_paymentHandler -> error occurred handling payment `,
      {
        code: e.code || "",
        message: e.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: true,
      description: e.message,
    }});
  }
}

async function _onDismissHandler(cb, data) {
  await _trackPaymentFailed({
    amount: data.amount,
    duration: data.duration,
    productName: data.product,
    error: "Payment cancelled by user!",
    phone: data.phone,
    email: data.email,
  });

  rollbarService?.logError(`_onDismissHandler -> payment cancelled `, {
    code: "payment/cancelled" || "",
    message: "Payment cancelled by user!",
  });

  trackGtagEvent({type:"event", event:"exception", payload: {
    fatal: false,
    description: "Payment cancelled by user!",
  }});

  cb();
}

function getRazorPayCheckoutOptions(data) {
  const options = {
    key: config.rpKey,
    amount: data.amount.toString(),
    currency: "INR",
    name: "Kutuki",
    description: "Kutuki Subscription",
    image: "https://s3.ap-south-1.amazonaws.com/kutuki/App+Icons/144.png",
    handler: (response) =>
       _paymentHandler({
          orderId: data.orderId,
          transactionId: data.transactionId,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
          postVerifyCb: data.postVerifyCb,
          couponCode: data.couponCode,
          amount: data.amount / 100,
          email: data.email,
          phone: data.phone,
      }),
    prefill: {
      name: data.name,
      email: data.email || config.default_email,
      contact: data.phone,
    },
    theme: {
      color: "#3777B4",
    },
    modal: {
      ondismiss: () =>
        _onDismissHandler(data.onDismiss, {
          duration: data.productDuration,
          product: data.productTitle,
          amount: data.amount / 100,
          email: data.email,
          phone: data.phone,
        }),
    },
  };

  if (data.orderId.includes("sub_")) {
    return { ...options, recurring: true, subscription_id: data.orderId };
  } else {
    return { ...options, order_id: data.orderId };
  }
}

async function createPayment({
  productId,
  productTitle,
  productDuration,
  couponCode,
  postVerifyCb,
  onDismiss,
}) {
  const apiClient = await APIClient({
    path: "/payment/subscription",
    payload: {
      productId,
      gateway: "razorpay",
      couponCode,
    },
  });

  const result = await apiClient.post();

  if (result.code !== 200) {
    console.error(`createPayment -> error creating the payment ${result.message}`);

    rollbarService?.logError(
      `createPayment -> error creating the payment ${result.message}`,
      {
        code: result.code || "",
        message: result.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: true,
      description: result.message
    }});
    
    throw new Error(result.message);
  }

  const data = result.data;

  trackGtagEvent({type: "event", event: "begin_checkout",payload: {
    currency: "INR",
    value: data.amountInPaise/100,
    coupon: couponCode || "",
    items: [{
      itemId: productId,
      itemName: productTitle
    }]
  }});

  return getRazorPayCheckoutOptions({
    amount: data.amountInPaise,
    orderId: data.orderId,
    transactionId: data.transactionId,
    name: sessionHelper.name,
    phone: sessionHelper.phone,
    email: sessionHelper.email,
    couponCode: couponCode,
    productTitle,
    productDuration,
    postVerifyCb,
    onDismiss,
  });
}

async function verifyPayment({ transactionId, orderId, paymentId, signature }) {
  const apiClient = await APIClient({
    path: "/payment/subscription/verify",
    payload: {
      transactionId,
      orderId,
      paymentId,
      signature,
    },
  });

  const result = await apiClient.post();

  if (result.code !== 200) {
    console.error(`verifyPayment -> error ${result.message}`);
    
    rollbarService?.logError(
      `verifyPayment -> error verifying the payment ${result.message}`,
      {
        code: result.code || "",
        message: result.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: true,
      description: result.message
    }});
    
    throw new Error(result.message);
  }

  return result.data;
}

async function getPaymentErrors() {

  const apiClient = await APIClient({
    path: "/payment/config"
  });

  const result = await apiClient.get();

  if (result.code !== 200) {
    console.error(`getPaymentErrors -> error ${result.message}`);
    rollbarService?.logError(
      `verifyPayment -> error in fetching payment errors ${result.message}`,
      {
        code: result.code || "",
        message: result.message,
      }
    );

    trackGtagEvent({type:"event", event:"exception", payload: {
      fatal: false,
      description: result.message
    }});

    throw new Error(result.message);
  }

  const {razorpayErrors} = result.data;

  return razorpayErrors;
}

export { loadScript, createPayment, verifyPayment, _trackPaymentFailed, getPaymentErrors };
