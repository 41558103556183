import Typography from './Typography';

function PrimaryTypography({ sx, children, ...props }) {
	return (
		<Typography
			sx={{
				fontFamily: 'Open Sans',
				...sx
			}}
			{...props}
		>
			{children}
		</Typography>
	);
}

function PrimaryTypographyHeading1({ children, ...props }) {
	return (
		<PrimaryTypography variant={'h1'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyHeading2({ children, ...props }) {
	return (
		<PrimaryTypography variant={'h2'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyHeading3({ children, ...props }) {
	return (
		<PrimaryTypography variant={'h3'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyHeading4({ children, ...props }) {
	return (
		<PrimaryTypography variant={'h4'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyHeading5({ children, ...props }) {
	return (
		<PrimaryTypography variant={'h5'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyHeading6({ children, ...props }) {
	return (
		<PrimaryTypography variant={'h6'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyText({ children, ...props }) {
	return (
		<PrimaryTypography variant={'body1'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographySubtitle({ children, ...props }) {
	return (
		<PrimaryTypography variant={'subtitle1'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyButton({ children, ...props }) {
	return (
		<PrimaryTypography variant={'button'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

function PrimaryTypographyCaption({ children, ...props }) {
	return (
		<PrimaryTypography variant={'caption'} {...props}>
			{children}
		</PrimaryTypography>
	);
}

export {
	PrimaryTypographyHeading1,
	PrimaryTypographyHeading2,
	PrimaryTypographyHeading3,
	PrimaryTypographyHeading4,
	PrimaryTypographyHeading5,
	PrimaryTypographyHeading6,
	PrimaryTypographyText,
	PrimaryTypographySubtitle,
	PrimaryTypographyButton,
	PrimaryTypographyCaption,
}
