import { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import {
  PrimaryTypographyCaption,
  PrimaryTypographyHeading4,
} from "./typography/PrimaryTypography";
import TextButton from "./buttons/TextButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "features/authenticate/services/authService";
import { useNavigation } from "../Router";
import MenuIcon from "@mui/icons-material/Menu";
import sessionHelper from "utils/sessionHelper";

// import logo from "assets/images/logo.png";

// const Logo = styled('img')({
//   display: 'flex',
//   height: 50,
// });

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Header(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { navigateTo } = useNavigation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSignOutClicked = () => {
    signOut().then(() => navigateTo("/auth/login"));
  };

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar color={"transparent"} sx={{ boxShadow: "none" }}>
          <Toolbar
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {props.isMobile ? (
              <IconButton
                size="large"
                aria-label="open drawer"
                aria-controls="basic-menu"
                aria-haspopup="true"
                edge="start"
                onClick={props?.handleDrawerToggle}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            {/* {props.isMobile ? <Logo src={logo} alt={"Kutuki"} /> : null} */}
            <PrimaryTypographyHeading4>
              {props.screenName}
            </PrimaryTypographyHeading4>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="basic-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              onClose={handleClose}
              sx={{
                mt: "36px",
                color: "black",
                "& .MuiMenu-paper": { backgroundColor: "white" },
              }}
            >
              <PrimaryTypographyCaption
                sx={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  p: 2,
                  fontSize: 14,
                  fontWeight: 800,
                }}
              >
                {(sessionHelper.email && sessionHelper.email !== "") ? sessionHelper.email : sessionHelper.phone}
              </PrimaryTypographyCaption>
              <TextButton
                sx={{ color: "grey", textTransform: "none", pl: 2, pr: 2 }}
                onClick={onSignOutClicked}
                startIcon={<LogoutIcon />}
              >
                Logout
              </TextButton>
            </Menu>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
}

Header.propTypes = {
  screenName: PropTypes.string,
};

export default Header;
