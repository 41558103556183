import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";

export default function InsightsSkeleton() {
  return (
    <Grid container spacing={4}>
      <Grid item md={6} sm={12} xs={12}>
        <Stack spacing={1}>
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} height={80} />
          <Skeleton variant="rectangular" width="100%" height={70} />
          <Skeleton
            variant="circular"
            height={200}
            width={200}
            sx={{
              alignSelf: "center",
            }}
          />
        </Stack>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Stack spacing={1}>
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} height={80} />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} height={80} />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} height={80} />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} height={80} />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} height={80} />
        </Stack>
      </Grid>
    </Grid>
  );
}
