import {styled} from "@mui/material/styles";
import facebookIcon from "assets/images/facebook.png";
import twitterIcon from "assets/images/twitter.png";
import instagramIcon from "assets/images/instagram.png";
import youtubeIcon from "assets/images/youtube.png";
import config from "configuration";

const FooterContainer = styled("footer")`
	width: 100%;
	padding: 10px 15px;
	text-align: center;
	background: #006086;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const ChannelIcon = styled("img")`
	display: inline-block;
	width: 30px;
	margin-left: 5px;
	margin-right: 5px;
	color: #fff;
`;

const SocialIcon = ({ url, icon, text }) => (
	<a href={url} target="_blank" rel="noreferrer">
		<ChannelIcon src={icon} alt={text} />
	</a>
);

function Footer() {
	return (
		<FooterContainer>
			<SocialIcon url={config.kutuki_facebook} icon={facebookIcon} text="fb" />
			<SocialIcon url={config.kutuki_twitter} icon={twitterIcon} text="x" />
			<SocialIcon url={config.kutuki_instagram} icon={instagramIcon} text="ig" />
			<SocialIcon url={config.kutuki_youtube} icon={youtubeIcon} text="yt" />
		</FooterContainer>
	);
}

export default Footer;
