import {APIClient} from "utils/httpClient";

async function getStaticOtp(phone) {
    const response = await new APIClient({
      path: `/auth/static/OTP/${phone}`,
    }).get();
  
    if (response.code !== 200) {
      throw new Error(response?.message || "Uh oh! Unable to get OTP");
    }
    
    return response;
}

export {
    getStaticOtp
}