import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import DrawIcon from "@mui/icons-material/Draw";
import { PrimaryTypographyCaption } from "components/typography/PrimaryTypography";
import PropTypes from 'prop-types';

export default function VerticalLinearStepper(props) {
  const getStepTitle = (activity) => {
    if (["content", "game"].includes(activity.activityType)) {
      return activity.title;
    }

    if (activity.activityType === "tracing") {
      return activity.displayName;
    }
  };

  const iconStyles = {
    color: "white",
    borderRadius: 100,
    p: "4px",
  };

  const getStepIcon = (activityType, isCompleted) => {
    switch (activityType) {
      case "content":
        return (
          <OndemandVideoIcon
            sx={{
              ...iconStyles,
              backgroundColor: isCompleted ? "#12B25C" : "grey",
            }}
          />
        );
      case "game":
        return (
          <SportsEsportsIcon
            sx={{
              ...iconStyles,
              backgroundColor: isCompleted ? "#12B25C" : "grey",
            }}
          />
        );
      case "tracing":
        return (
          <DrawIcon
            sx={{
              ...iconStyles,
              backgroundColor: isCompleted ? "#12B25C" : "grey",
            }}
          />
        );
      default:
        return (
          <SportsEsportsIcon
            sx={{
              ...iconStyles,
              backgroundColor: isCompleted ? "#12B25C" : "grey",
            }}
          />
        );
    }
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper
        orientation="vertical"
        sx={{
          "& .Mui-disabled .MuiStepIcon-root": { color: "grey" },
          "& .Mui-active .MuiStepIcon-root": { color: "#12B25C" },
        }}
      >
        {props.data.map((step, index) => (
          <Step key={step.id} completed={true}>
            <StepLabel
              StepIconProps={{
                icon: getStepIcon(step.activityType, step.isCompleted),
              }}
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 700,
              }}
            >
              <PrimaryTypographyCaption
                sx={{
                  fontWeight: 600,
                }}
              >
                {getStepTitle(step)}
              </PrimaryTypographyCaption>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

VerticalLinearStepper.propTypes = {
	data: PropTypes.array,
}
